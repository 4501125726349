// create a card state using the card ids
export const constructCardState = (cards: any[], cardState: any) => {
  const keys = Object.keys(cardState);
  const data: any = {};
  keys.forEach((key) => {
    data[key] = [];

    cardState[key].forEach((id: any) => {
      const card = cards.find(({ _id }) => _id == id);
      data[key].push(card);
    });
  });

  return data;
};
