import ReactDOM from "react-dom/client";
import "./global.scss";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Workshop } from "./modules/Workshop";
import { AdminWorkshops } from "modules/AdminWorkshops";
import { AdminWorkshop } from "modules/AdminWorkshop";
import { AdminLogin } from "modules/AdminLogin";
import { Page404 } from "components/404";
import { WorkshopDescription } from "modules/WorkshopDescription";
import { AdminSettings } from "modules/AdminSettings";
import { AdminDecks } from "modules/AdminDecks";
import { AdminDeck } from "modules/AdminDeck";
import { Profile } from "modules/Profile";
import { Home } from "modules/Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "workshop",
    element: <Workshop />,
  },
  {
    path: "workshop-description",
    element: <WorkshopDescription />,
  },
  {
    path: "admin",
    element: <Navigate to="/admin/workshops" replace />,
  },
  {
    path: "admin/settings",
    element: <AdminSettings />,
  },
  {
    path: "admin/decks",
    element: <AdminDecks />,
  },
  {
    path: "admin/decks/:id",
    element: <AdminDeck />,
  },
  {
    path: "admin/workshops",
    element: <AdminWorkshops />,
  },
  {
    path: "admin/workshops/:id",
    element: <AdminWorkshop />,
  },
  {
    path: "admin/login",
    element: <AdminLogin />,
  },
  {
    path: "admin/profile",
    element: <Profile />,
  },
  { path: "*", element: <Page404 /> },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <Toaster />
    <RouterProvider router={router} />
  </>
);
