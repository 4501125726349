import { AdminLayout, PageHeader, Button } from "components";
import { useEffect, useState } from "react";
import { WorkshopCreateForm } from "./WorkshopCreateForm";
import { extractError } from "utils";
import { useWorkshopStore } from "store";
import { Plus, RotateCw } from "react-feather";
import { toast } from "react-hot-toast";
import { WorkshopGrid } from "./WorkshopGrid";

export const AdminWorkshops = () => {
  const { workshops, getWorkshops, loading, loaded, archived } =
    useWorkshopStore((state: any) => state);

  const [showForm, setShowForm] = useState(false);

  const loadWorkshops = (archived: boolean) =>
    getWorkshops({
      values: {
        archived,
      },
      onError: (err: any) => {
        toast.error(extractError(err));
      },
    });

  useEffect(() => {
    if (!loaded) loadWorkshops(archived);
  }, []);

  return (
    <AdminLayout loading={loading}>
      <WorkshopCreateForm
        switchToActive={() => {
          loadWorkshops(false);
        }}
        isOpen={showForm}
        onClose={() => setShowForm(false)}
      />
      <PageHeader
        title="Workshops"
        subTitle={`(showing ${!archived ? "active" : "archived"} workshops)`}
        buttons={[
          <Button
            key="reload"
            title="Reload"
            onClick={() => loadWorkshops(archived)}
            icon={<RotateCw size="16px" />}
          />,
          <Button
            key="show"
            title={"Show " + (!archived ? "Archived" : "Active")}
            onClick={() => {
              loadWorkshops(!archived);
            }}
          />,
          <Button
            key="create"
            title="Create New"
            onClick={() => setShowForm(true)}
            icon={<Plus />}
          />,
        ]}
      >
        <WorkshopGrid workshops={workshops} />
      </PageHeader>
    </AdminLayout>
  );
};
