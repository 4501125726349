import { useDeckStore } from "store";
import { ViewWrapper } from "../components";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { extractError } from "utils";
import { AlertBox } from "components/AlertBox";
import { Button } from "components";
import { useWorkshop, useWorkshopActions } from "../workshopProvider";

export const Decks = () => {
  const { workshop } = useWorkshop();

  const { getDecks, decks, loaded, loading } = useDeckStore(
    (state: any) => state
  );

  const { assignDecks } = useWorkshopActions();

  const [selectedDecks, setSelectedDecks] = useState<string[]>([]);

  useEffect(() => {
    if (workshop) {
      const selectedDecks = workshop.decks.map(({ _id }: any) => _id);
      setSelectedDecks(selectedDecks);
    }
  }, [workshop]);

  const loadDecks = async () => {
    await getDecks({
      onSuccess: () => {},
      onError: (err: any) => {
        toast.error(extractError(err));
      },
    });
  };

  const deckFixed = workshop.locked || workshop.archived;

  useEffect(() => {
    if (deckFixed) return;
    if (!loaded) loadDecks();
  }, []);

  if (deckFixed) {
    return (
      <ViewWrapper title="Decks (in use)">
        <div className="grid grid-rows-2 grid-cols-5 gap-3 w-[100%]">
          {workshop.decks.map((deck: any) => {
            return (
              <div
                key={deck._id}
                className={
                  "border border-black p-3 px-5 rounded-md min-h-[100px] relative w-full text-left "
                }
              >
                <p>ID: {deck.id}</p>
                <p className=" text-2xl">{deck.name}</p>
              </div>
            );
          })}
        </div>
      </ViewWrapper>
    );
  }

  return (
    <ViewWrapper
      title="Decks"
      actions={
        <Button
          title="Assign Decks"
          onClick={() => assignDecks(selectedDecks)}
        />
      }
    >
      {loading && <div>Loading Decks</div>}
      {!loading && decks.length == 0 && (
        <AlertBox
          title="No decks available"
          subtitle="Please create decks to proceed with workshop sessions"
        />
      )}
      {!loading && decks.length > 0 && (
        <div className="grid grid-rows-2 grid-cols-5 gap-3 w-[100%]">
          {decks.map((deck: any) => {
            const selected = selectedDecks.includes(deck._id);
            return (
              <button
                key={deck._id}
                onClick={() => {
                  if (selectedDecks.includes(deck._id)) {
                    const newDecks = selectedDecks.filter(
                      (id) => id !== deck._id
                    );
                    setSelectedDecks(newDecks);
                  } else {
                    const newDecks = [deck._id, ...selectedDecks];
                    setSelectedDecks(newDecks);
                  }
                }}
                className={
                  "border border-black p-3 px-5 rounded-md min-h-[100px] relative w-full text-left " +
                  (selected ? "bg-black text-white" : "")
                }
              >
                <p>ID: {deck.id}</p>
                <p className=" text-2xl">{deck.name}</p>
                {selected && (
                  <div className="absolute top-2 right-2 bg-white text-black px-1 rounded-md font-bold text-sm">
                    SELECTED
                  </div>
                )}
              </button>
            );
          })}
        </div>
      )}
    </ViewWrapper>
  );
};
