import { Button, Modal } from "components";
import {
  useWorkshop,
  useWorkshopActions,
} from "modules/Workshop/workshopProvider";
import { FC, useEffect, useState } from "react";
import { Book, Info, X } from "react-feather";
import parse from "html-react-parser";
import { ErrorBoundary } from "react-error-boundary";

const DeckDetails: FC<{ deckObject: any }> = ({ deckObject }) => {
  return (
    <ErrorBoundary fallback={<></>}>
      <div className="border border-gray-300 bg-gray-200 p-2 rounded-md">
        <div className="flex gap-2 items-center">
          <Info size={20} />
          <span className=" font-bold text-lg">DECK DETAILS</span>
        </div>
        <ErrorBoundary fallbackRender={() => <>{deckObject.details}</>}>
          {parse(deckObject.details || "")}
        </ErrorBoundary>
      </div>
    </ErrorBoundary>
  );
};

const DeckInstructions: FC<{
  deckObject: any;
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose, deckObject }) => {
  return (
    <ErrorBoundary fallback={<></>}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="relative p-5 bg-white rounded-md w-[600px] flex flex-col items-center gap-5">
          <h2 className="text-3xl font-bold text-center">Instructions</h2>
          <button className="absolute top-3 right-3">
            <X size={30} onClick={onClose} />
          </button>
          <div className="border-b border-black w-[200px]" />
          <div className="w-full">
            <ErrorBoundary
              fallbackRender={() => <>{deckObject.instructions}</>}
            >
              {parse(deckObject.instructions || "")}
            </ErrorBoundary>
          </div>
        </div>
      </Modal>
    </ErrorBoundary>
  );
};

export const Header: FC<{}> = ({}) => {
  const { workshop, decks, participantCount, selectedDeck } = useWorkshop();
  const { setSelectedDeck } = useWorkshopActions();
  const [showInstructions, setShowInstructions] = useState(false);
  const [selectedDeckObject, setSelectedDeckObject] = useState<any>({});

  useEffect(() => {
    try {
      const selectedDeckObject = decks.find((s: any) => s._id == selectedDeck);
      setSelectedDeckObject(selectedDeckObject);
    } catch {}
  }, [selectedDeck]);

  return (
    <>
      <DeckInstructions
        deckObject={selectedDeckObject}
        isOpen={showInstructions}
        onClose={() => setShowInstructions(false)}
      />
      <div className="m-[20px] flex flex-row justify-between items-start pb-5 gap-3">
        <div className="flex gap-3 items-center">
          <h1 className="text-2xl font-bold pb-1">{workshop.name}</h1>
          <span className=" text-gray-500">
            {participantCount} {`Participant(s)`}
          </span>
          <div className="border border-l border-gray-300 h-[20px]"></div>
          <span className=" text-gray-500  ">
            Joined as{" "}
            <span className="font-bold">{workshop.participantInfo.email}</span>
          </span>
        </div>
        <div className="flex-1">
          <DeckDetails deckObject={selectedDeckObject} />
        </div>
        <div className="flex gap-2 items-center">
          <div className="flex items-center bg-black rounded-md">
            <div className="text-lg  text-white px-2">
              <span className="block">DECK</span>
            </div>
            <select
              value={selectedDeck}
              onChange={(e) => setSelectedDeck(e.target.value)}
              className="w-[250px] p-2 rounded-md rounded-l-none border border-black font-bold bg-white"
            >
              {decks.map((deck: any) => (
                <option key={"dropdown" + deck._id} value={deck._id}>
                  {deck.name}
                </option>
              ))}
            </select>
          </div>

          <Button
            onClick={() => setShowInstructions(true)}
            icon={<Book />}
            title="Instructions"
            disabled={!selectedDeckObject.instructions}
          />
        </div>
      </div>
    </>
  );
};
