import { FC, useEffect, useState } from "react";
import { Header } from "./Header";
import { Button } from "components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "store";
import { Loader } from "react-feather";

export const AdminLayout: FC<{
  children: any;
  hideNav?: boolean;
  loading?: boolean;
}> = ({ children, hideNav, loading: loadingView }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { checkSession, loading, user } = useAuthStore((state: any) => state);

  useEffect(() => {
    if (!user) {
      checkSession({
        values: { initialPath: location.pathname },
        onSuccess: (values: any) => {
          const { initialPath } = values;
          if (initialPath == "/admin/login") navigate("/admin");
          else navigate(values.initialPath);
        },
        onError: () => {
          navigate("/admin/login");
        },
      });
    }
  }, []);

  if (loading)
    return (
      <div className="flex items-center justify-center h-[100vh]">
        <div className="flex flex-col justify-center items-center">
          <Loader className="animate-spin " size={"50px"} />
          <h1 className="text-xl uppercase font-bold">
            Checking Login Session
          </h1>
        </div>
      </div>
    );

  if (hideNav) return children;

  return (
    <div className="relative flex flex-col h-[100vh]">
      {loadingView && (
        <div className="fixed w-[100vw] h-[100vh] z-[5000]">
          <div className="z-[1000] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col items-center text-white">
            <Loader className="animate-spin " size={"50px"} />
            <span className="font-bold">LOADING</span>
          </div>
          <div className="absolute top-0 left-0 bg-black w-[100vw] h-[100vh] z-50 opacity-50"></div>
        </div>
      )}
      <Header />
      <div className="m-[20px] mt-[10px] flex-1">{children}</div>
    </div>
  );
};
