import { Button, Modal } from "components";
import { useFormik } from "formik";
import {
  useWorkshop,
  useWorkshopActions,
} from "modules/AdminWorkshop/workshopProvider";
import { FC, useState, useEffect } from "react";
import { Loader } from "react-feather";

export const FeedbackBox: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const { participantState, workshop } = useWorkshop();
  const { submitFeedback } = useWorkshopActions();

  const formik = useFormik({
    onSubmit: (values) => {
      setLoading(true);
      submitFeedback({
        values: { email: isOpen, ...values },
        onSuccess: () => {
          onClose();
        },
        onComplete: () => {
          setLoading(false);
        },
      });
    },
    initialValues: { feedback: "" },
  });

  useEffect(() => {
    if (isOpen) {
      try {
        const { feedback } = participantState[String(isOpen)];
        formik.setValues({ feedback });
      } catch {}
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        !loading && onClose();
      }}
    >
      <div className="bg-white w-[500px] rounded-md relative overflow-hidden">
        {loading && (
          <div className="flex items-center justify-center h-[100%] absolute w-full">
            <Loader className="animate-spin " size={"50px"} />
          </div>
        )}
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-5 items-center p-5 "
        >
          <h3 className="text-xl font-bold text-center">
            Feedback for {isOpen}
          </h3>
          <textarea
            disabled={workshop.archived}
            value={formik.values.feedback}
            onChange={formik.handleChange}
            name="feedback"
            rows={7}
            className="border border-black rounded-md w-full p-3 py-2 resize-none"
          ></textarea>
          {!workshop.archived && (
            <Button type="submit" title="Submit Feedback" />
          )}
        </form>
      </div>
    </Modal>
  );
};
