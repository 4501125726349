import { Button } from "components/Button";
import dayjs from "dayjs";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

export const WorkshopCard: FC<{ workshop: any }> = ({ workshop }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate("/admin/workshops/" + workshop._id)}
      className="border border-black rounded-md p-5 py-4 flex flex-col
    gap-2 h-full justify-between w-full text-left hover:bg-black hover:text-white group
     transition-all duration-100 "
    >
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          {!workshop.archived && (
            <>
              {workshop.locked && (
                <span className="bg-black text-xs group-hover:bg-white group-hover:text-black  text-white p-1 px-2 rounded-md">
                  LOCKED
                </span>
              )}
              <span className="bg-black text-xs group-hover:bg-white group-hover:text-black text-white p-1 px-2 rounded-md">
                {workshop.public ? "PUBLIC" : "PRIVATE"}
              </span>
            </>
          )}
          {workshop.archived && (
            <span className="bg-black text-xs group-hover:bg-white group-hover:text-black text-white p-1 px-2 rounded-md">
              ARCHIVED
            </span>
          )}
        </div>
        <span className="font-bold uppercase text-2xl">{workshop.name}</span>
        <div className="flex text-slate-500 group-hover:text-white gap-2 uppercase text-xs">
          <span>{workshop.participants.length} Participants</span>
          <div className="border-r border-slate-300"></div>
          <span>
            Created On {dayjs(workshop.createdAt).format("DD MMM YYYY")}
          </span>
        </div>
      </div>
    </button>
  );
};
