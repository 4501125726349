import { AdminLayout } from "components";
import { Button } from "components/Button";
import { FC } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import { Participants, Analytics, Description, Decks, Overview } from "./views";
import {
  WorkshopProvider,
  useWorkshop,
  useWorkshopActions,
} from "./workshopProvider";
import { LoadingView } from "./components";
import { Loader, RotateCw } from "react-feather";

const WorkshopRouter: FC<{ hash: string }> = ({ hash }) => {
  if (hash == "#analytics") return <Analytics />;
  if (hash == "#participants") return <Participants />;
  if (hash == "#decks") return <Decks />;
  if (hash == "#description") return <Description />;
  return <Overview />;
};

const AdminWorkshopInner = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { load } = useWorkshopActions();
  const { workshop, loading, error, actionLoading } = useWorkshop();

  if (error) {
    navigate("/admin/workshops");
  }

  if (loading || !workshop)
    return (
      <AdminLayout>
        <LoadingView />
      </AdminLayout>
    );

  return (
    <AdminLayout>
      {actionLoading && (
        <div className="absolute top-0 left-0 w-[100vw] h-[100vh]  flex items-center justify-center">
          <div className="absolute bg-black w-[100vw] h-[100vh] opacity-50 z-[100000]"></div>
          <div>
            <Loader className="animate-spin " size={"50px"} />
          </div>
        </div>
      )}
      <div className="flex justify-between items-center">
        <div className="flex gap-2  justify-start items-end">
          <span className="uppercase text-gray-400 text-2xl">
            Workshop Name:
          </span>
          <h1 className="text-3xl font-bold">{workshop.name}</h1>
        </div>
        <div className="flex gap-2">
          <Button
            title="Reload"
            onClick={load}
            icon={<RotateCw size="16px" />}
          />
        </div>
      </div>
      <div className="relative mt-8 flex gap-10">
        <div className="w-[250px]">
          <Sidebar />
        </div>
        <div className="border rounded-md w-full p-3 px-5">
          <WorkshopRouter hash={hash} />
        </div>
      </div>
    </AdminLayout>
  );
};

export const AdminWorkshop = () => {
  const { id } = useParams();
  return (
    <WorkshopProvider id={id}>
      <AdminWorkshopInner />
    </WorkshopProvider>
  );
};
