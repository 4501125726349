import { Card, Modal } from "components";
import { FC, useState, useEffect, useMemo } from "react";
import { X } from "react-feather";
import { useWorkshop } from "../workshopProvider";
import { constructCardState } from "utils";

import collection1 from "assets/collectionImages/collection1.png";
import collection2 from "assets/collectionImages/collection2.png";
import collection3 from "assets/collectionImages/collection3.png";
import collection4 from "assets/collectionImages/collection4.png";

export const Stack: FC<{ cards: any[] }> = ({ cards }) => {
  if (!cards) return <></>;
  return (
    <div className="relative rounded-md h-[100%] transition-all duration-200">
      {cards.map((item: any, index: number) => {
        return (
          <div key={item._id}>
            <Card absolute cardInfo={item} />
          </div>
        );
      })}
    </div>
  );
};

export const Collection: FC<{
  title: string;
  icon: string;
  id: string;
  cards: any;
}> = ({ title, icon, cards }) => {
  if (!cards) return <></>;
  return (
    <div className="h-[100%]  rounded-md  transition-all duration-200">
      <div className="flex flex-col gap-4">
        <div className="gap-5 h-[10vw] bg-black rounded-lg text-white p-3 flex justify-center flex-col items-center">
          <img className="object-contain h-[60px]" src={icon} alt={title} />
          <span className=" text-xl">{title}</span>
        </div>
        {cards.map((item: any, index: number) => {
          return (
            <div key={item._id}>
              <Card cardInfo={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const LiveView: FC<{ email: string; onClose: () => void }> = ({
  email,
  onClose,
}) => {
  const {
    workshop,
    cardState,
    workshop: { cards, decks },
  } = useWorkshop();
  const [selectedDeck, setSelectedDeck] = useState<any>("");

  useEffect(() => {
    try {
      setSelectedDeck(decks[0]._id);
    } catch {}
  }, [email]);

  const data = useMemo(() => {
    try {
      return constructCardState(cards, cardState[email][selectedDeck] || {});
    } catch {
      return null;
    }
  }, [email, cardState, selectedDeck]);

  return (
    <Modal isOpen={Boolean(email)} onClose={onClose}>
      <div className="h-[100vh] w-[100vw] bg-white fixed top-0 left-0 p-3 px-5">
        <div className="flex justify-between">
          <div className="flex gap-2 items-center">
            <h1 className="text-2xl">
              Workshop: <b>{workshop && workshop.name}</b> | View of{" "}
              <b>{email}</b> |
            </h1>
            <select
              value={selectedDeck}
              onChange={(e) => {
                setSelectedDeck(e.target.value);
              }}
              className="w-[250px] p-2  rounded-md border border-black font-bold bg-white"
            >
              {decks.map((deck: any) => (
                <option key={"dropdown" + deck._id} value={deck._id}>
                  {deck.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <button onClick={onClose}>
              <X size={40} />
            </button>
          </div>
        </div>
        <div className="flex flex-1 m-[20px] gap-[50px]">
          <div className="flex-1">{data && <Stack cards={data.stack} />}</div>
          <div className="flex-1">
            {data && (
              <Collection
                title="You are."
                icon={collection1}
                id="collection1"
                cards={data.collection1}
              />
            )}
          </div>
          <div className="flex-1">
            {data && (
              <Collection
                title="You are not."
                icon={collection2}
                id="collection2"
                cards={data.collection2}
              />
            )}
          </div>
          <div className="flex-1">
            {data && (
              <Collection
                title="You are torn."
                icon={collection3}
                id="collection3"
                cards={data.collection3}
              />
            )}
          </div>
          <div className="flex-1">
            {data && (
              <Collection
                title="Does not apply."
                icon={collection4}
                id="collection4"
                cards={data.collection4}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
