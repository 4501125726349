export const constructCardState = (cards: any[], cardState: any) => {
  const _state: any = {};

  Object.keys(cardState).forEach((id) => {
    const state = cardState[id];

    const keys = Object.keys(state);

    const data: any = {};

    keys.forEach((key: any) => {
      const cardIds = state[key];

      let _cards = [];
      try {
        _cards = cards.filter((card) => cardIds.includes(card._id));
      } catch {}

      data[key] = _cards;
    });
    _state[id] = data;
  });
  return _state;
};
