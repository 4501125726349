import { apiCaller } from "utils";
import { create } from "zustand";
import { ActionArgs } from "store";

export const useCardStore = create((set) => ({
  cards: [],
  loaded: false,
  loading: true,
  getCards: async ({ onSuccess, onError }: ActionArgs) => {
    set((state: any) => ({ ...state, loading: true }));
    try {
      const {
        data: { rows },
      } = await apiCaller.get("/cards");
      set((state: any) => ({
        ...state,
        cards: rows,
        loading: false,
        loaded: true,
      }));
      onSuccess && onSuccess();
    } catch (err) {
      set((state: any) => ({ ...state, loading: false }));
      onError && onError(err);
    }
  },
}));
