import { Modal } from "components";
import { useWorkshop } from "./workshopProvider";

export const FeedbackPopup = () => {
  const { feedback } = useWorkshop();

  if (!Boolean(feedback)) return <></>;

  return (
    <Modal isOpen={true} onClose={() => {}}>
      <div className="bg-white rounded-md p-6 px-5 w-[30vw] min-w-[600px] text-center flex flex-col gap-3">
        <h1 className="text-2xl font-bold">Feedback</h1>
        <p>{feedback}</p>
      </div>
    </Modal>
  );
};
