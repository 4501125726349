import { FC } from "react";
import { Header } from "./Header";

export const WorkshopLayout: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-[100vh]">
      <Header />
      {children}
    </div>
  );
};
