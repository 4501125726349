import { Button } from "components";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <div className="flex  items-center justify-center h-[100vh]">
      <div className="flex flex-col items-center">
        <h2 className=" text-xl">Welcome to</h2>
        <div className="flex">
          <h1 className=" text-4xl font-bold">BrandSoul Disruption Workshop</h1>
          <span>TM</span>
        </div>
        <div className="border-b border-black w-[300px] pt-5 mb-5" />
        <Link to={"/admin/login"}>
          <Button title="Login" />
        </Link>
      </div>
    </div>
  );
};
