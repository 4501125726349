import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { apiCaller } from "utils";
import parse from "html-react-parser";
import { Loader } from "react-feather";
import { ErrorBoundary } from "react-error-boundary";

export const WorkshopDescription = () => {
  const { search } = useLocation();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);

  const getDescription = async () => {
    const {
      data: { data },
    } = await apiCaller.get(`/workshops/description` + search);
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getDescription();
  }, []);

  if (loading)
    return (
      <div className="flex items-center justify-center h-[100vh]">
        <div className="flex flex-col items-center justify-center gap-3">
          <Loader className="animate-spin " size={"50px"} />
          <p className="uppercase font-bold">Loading Description</p>
        </div>
      </div>
    );

  return (
    <div className="flex min-h-[100vh] justify-center">
      <div className="w-[500px] mt-[100px]">
        <div className="border border-black rounded-lg overflow-hidden">
          <div className="p-3 px-5 bg-black text-white">
            <h1 className="text-lg uppercase">
              Description for <b>{data.name}</b>
            </h1>
          </div>
          <div className="border-b border-black"></div>
          <div className="p-3 px-5">
            <ErrorBoundary fallbackRender={() => <>{data.description}</>}>
              {parse(data.description || "")}
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};
