import { useState, useEffect } from "react";
import { ViewWrapper } from "../../components";
import { TopFiveCards } from "./TopFiveCards";
import { OverallCards } from "./OverallCards";
import { useWorkshop } from "modules/AdminWorkshop/workshopProvider";
import { AlertBox } from "components/AlertBox";

const VIEWS = [
  {
    id: 0,
    label: "Top 5 Cards / Column",
  },
  {
    id: 1,
    label: "Overall Cards Info",
  },
];

export const Analytics = () => {
  const [view, setView] = useState(0);
  const { workshop } = useWorkshop();
  const [deckId, setDeckId] = useState("");

  useEffect(() => {
    if (workshop.locked) {
      setDeckId(workshop.decks[0]._id);
    }
  }, [workshop.locked]);

  if (!workshop.locked)
    return (
      <ViewWrapper title="Analytics">
        {!workshop.locked && (
          <AlertBox
            title="Please lock the workshop to view analytics"
            subtitle="You must lock the workshop in order to view the analytics"
          />
        )}
      </ViewWrapper>
    );

  return (
    <ViewWrapper
      title="Analytics"
      actions={
        <select
          value={deckId}
          onChange={(e) => setDeckId(e.target.value)}
          className="w-[250px] p-2 rounded-md border border-black font-bold bg-white"
        >
          {workshop.decks.map((deck: any) => (
            <option key={"dropdown" + deck._id} value={deck._id}>
              {deck.name}
            </option>
          ))}
        </select>
      }
    >
      <div>
        <nav>
          <ul className="flex gap-3">
            {VIEWS.map(({ id, label }) => (
              <li
                key={id}
                className={
                  "border border-black rounded-md p-[2px] px-2 uppercase hover:bg-black hover:text-white transition-all duration-200" +
                  (view == id ? " bg-black text-white " : "")
                }
              >
                <button onClick={() => setView(id)}>{label}</button>
              </li>
            ))}
          </ul>
        </nav>
        <div className="pt-5">
          {view == 0 && <TopFiveCards deckId={deckId} />}
          {view == 1 && <OverallCards deckId={deckId} />}
        </div>
      </div>
    </ViewWrapper>
  );
};
