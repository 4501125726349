import { serialize } from "object-to-formdata";
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDeckStore } from "store";
import { apiCaller, extractError } from "utils";

const DeckContext = createContext<any>({});

const DeckActionsContext = createContext<any>({});

export const useDeck = () => useContext(DeckContext);

export const useDeckActions = () => useContext(DeckActionsContext);

export const DeckProvider: React.FC<{
  children: ReactNode;
  id: string | undefined;
}> = ({ children, id }) => {
  const [loading, setLoading] = useState(true);
  const [deck, setDeck] = useState({});
  const [cards, setCards] = useState([]);

  const navigate = useNavigate();
  const { removeDeck, expire } = useDeckStore((state: any) => state);

  const createCard = async (data: { values: any; onSuccess: () => void }) => {
    setLoading(true);
    try {
      const frontBgImage = data.values.front.backgroundImage;
      const backBgImage = data.values.back.backgroundImage;

      const newValues = JSON.parse(JSON.stringify(data.values));
      delete newValues.back.backgroundImage;
      delete newValues.front.backgroundImage;

      const formData = serialize(newValues);

      formData.append("frontBgImage", frontBgImage);
      formData.append("backBgImage", backBgImage);
      formData.append("deckId", String(id));

      const {
        data: { data: card },
      } = await apiCaller.post("/cards", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      data.onSuccess();
      toast.success("New card successfully created");

      const _cards: any = [card, ...cards];
      setCards(_cards);
    } catch (err) {
      toast.error(extractError(err));
    }
    setLoading(false);
  };

  const updateCard = async (data: { values: any; onSuccess: () => void }) => {
    setLoading(true);
    try {
      const frontBgImage = data.values.front.backgroundImage;
      const backBgImage = data.values.back.backgroundImage;

      const newValues = JSON.parse(JSON.stringify(data.values));
      delete newValues.back.backgroundImage;
      delete newValues.front.backgroundImage;

      const formData = serialize(newValues);

      formData.append("frontBgImage", frontBgImage);
      formData.append("backBgImage", backBgImage);

      const {
        data: { data: card },
      } = await apiCaller.put("/cards", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      data.onSuccess();
      toast.success("Card successfully updated");

      const index = cards.findIndex(({ _id }: any) => _id == card._id);
      const _cards: any = [...cards];
      _cards[index] = card;
      setCards(_cards);
    } catch (err) {
      toast.error(extractError(err));
    }
    setLoading(false);
  };

  const deleteCard = async (data: { values: any; onSuccess: () => void }) => {
    setLoading(true);
    try {
      const { _id } = data.values;
      await apiCaller.delete("/cards/" + _id);
      const newCards = cards.filter(({ _id }: any) => _id != data.values._id);
      setCards(newCards);
      data.onSuccess();
    } catch (err) {
      toast.error(extractError(err));
    }
    setLoading(false);
  };

  const loadDeck = async () => {
    setLoading(true);
    try {
      const {
        data: {
          data: { cards, ...deck },
        },
      } = await apiCaller.get("/decks/" + id);
      setDeck(deck);
      setCards(cards);
    } catch (err) {
      navigate("/admin/decks");
      toast.error(extractError(err));
    }
    setLoading(false);
  };

  const updateDeck = async (data: { values: any; onSuccess: () => void }) => {
    setLoading(true);
    try {
      const {
        data: { data: deck },
      } = await apiCaller.put("/decks/", data.values);
      data.onSuccess();
      expire();
      setDeck(deck);
    } catch (err) {
      toast.error(extractError(err));
    }
    setLoading(false);
  };

  const updateDeckInfo = async (data: { values: any }) => {
    setLoading(true);
    try {
      const {
        data: { data: deck },
      } = await apiCaller.post("/decks/info", { _id: id, ...data.values });
      setDeck(deck);
      toast.success("The deck information has been updated");
    } catch (err) {
      toast.error(extractError(err));
    }
    setLoading(false);
  };

  const deleteDeck = async () => {
    try {
      await apiCaller.delete("/decks/" + id);
      toast.success("Deck has been deleted");
      removeDeck(id);
      navigate("/admin/decks");
    } catch (err) {
      toast.error(extractError(err));
    }
  };

  useEffect(() => {
    loadDeck();
  }, []);

  return (
    <DeckContext.Provider value={{ deck, loading, cards }}>
      <DeckActionsContext.Provider
        value={{
          loadDeck,
          updateDeck,
          updateDeckInfo,
          createCard,
          updateCard,
          deleteCard,
          deleteDeck,
        }}
      >
        {children}
      </DeckActionsContext.Provider>
    </DeckContext.Provider>
  );
};
