import { Button, Modal } from "components";
import { FC, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";
import { useDeck, useDeckActions } from "../deckProvider";

export const DeckInfo: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const { deck } = useDeck();
  const { updateDeckInfo } = useDeckActions();

  const [mode, setMode] = useState<"details" | "instructions">("details");
  const editorRef: any = useRef(null);

  const [data, setData] = useState("");

  useEffect(() => {
    try {
      if (!isOpen) {
        editorRef.current.setContent("");
      } else {
        editorRef.current.setContent(deck[mode]);
      }
    } catch {}
  }, [isOpen]);

  useEffect(() => {
    try {
      editorRef.current.setContent(deck[mode]);
    } catch {}
  }, [mode, editorRef.current]);

  const onSave = () => {
    updateDeckInfo({ values: { [mode]: data } });
  };

  const handleChange = (content: any) => {
    setData(content);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col bg-white p-5 rounded-md w-[600px] gap-2 items-center">
        <span className="block text-center text-2xl uppercase font-bold">
          Update Deck Information
        </span>
        <div className="flex gap-2 items-center justify-center">
          <div>
            EDITING: <b>{mode.toUpperCase()}</b>
          </div>
          <Button
            onClick={() =>
              setMode(mode == "instructions" ? "details" : "instructions")
            }
            size="sm"
            title={`Switch to ${
              mode == "instructions" ? "details" : "instructions"
            }`}
          />
        </div>

        <Editor
          ref={editorRef}
          tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={deck[mode]}
          init={{
            height: 400,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          onEditorChange={handleChange}
        />
        <div>
          <Button title={`Update ${mode}`.toUpperCase()} onClick={onSave} />
        </div>
      </div>
    </Modal>
  );
};
