import { Button } from "components/Button";
import { Link } from "react-router-dom";

export const Page404 = () => {
  return (
    <div className="flex h-[100vh] w-[100vw] items-center justify-center">
      <div className="flex flex-col items-center gap-2">
        <h1 className="text-8xl">404</h1>
        <h2 className="text-2xl">Page Not Found</h2>
        <Link to="/">
          <Button title={"GO TO HOME"} />
        </Link>
      </div>
    </div>
  );
};
