import { FC } from "react";

export const FormikInput: FC<any> = ({ label, formik, ...props }) => {
  const { values, errors } = formik;
  const value = props.value || values[props.name];
  const error = props.error || errors[props.name];

  return (
    <div>
      {label && <label>{label}</label>}
      <input
        onChange={formik.handleChange}
        value={value}
        type="text"
        {...props}
        className="border border-gray-400 rounded-md w-full p-1 px-3"
      />
      {error && <span className=" text-red-600 text-sm">{error}</span>}
    </div>
  );
};
