import { Button } from "components";
import { ViewWrapper } from "../components";
import { useWorkshop, useWorkshopActions } from "../workshopProvider";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";

export const Description = () => {
  const { workshop } = useWorkshop();
  const { updateDescription } = useWorkshopActions();
  const editorRef: any = useRef(null);

  const [data, setData] = useState("");

  const onSave = () => {
    updateDescription({ data });
  };

  const handleChange = (content: any) => {
    setData(content);
  };

  return (
    <ViewWrapper title="Description">
      <div className="flex flex-col gap-3">
        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={workshop.description}
          init={{
            height: 400,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          onEditorChange={handleChange}
        />
        <div>
          <Button title="Save Description" onClick={onSave} />
        </div>
      </div>
    </ViewWrapper>
  );
};
