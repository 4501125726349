import { useWorkshop } from "modules/AdminWorkshop/workshopProvider";
import { FC } from "react";

import collection1 from "assets/collectionImages/collection1.png";
import collection2 from "assets/collectionImages/collection2.png";
import collection3 from "assets/collectionImages/collection3.png";
import collection4 from "assets/collectionImages/collection4.png";
import { Card } from "components";

const getTopCards = (deckId: any, cards: any, cardState: any) => {
  const collectionKeys = [
    "collection1",
    "collection2",
    "collection3",
    "collection4",
  ];

  const information: any = {};

  collectionKeys.forEach((key) => {
    const cardIds = Object.keys(cardState)
      .map((d: any) => {
        return cardState[d][deckId][key];
      })
      .flat();

    const uniqueCardIds = [...new Set(cardIds)];

    let data: any = [];

    uniqueCardIds.forEach((id) => {
      data.push({ id, count: cardIds.filter((c) => c === id).length });
    });

    data.sort(function (a: any, b: any) {
      return b.count - a.count;
    });

    data = data.splice(0, 5);

    data.forEach((d: any, index: number) => {
      const card = cards.find((c: any) => c._id == d.id);
      data[index].card = card;
    });
    information[key] = data;
  });
  return information;
};

export const Collection: FC<{
  title: string;
  icon: string;
  id: string;
  cards: any;
}> = ({ title, icon, cards }) => {
  if (!cards) return <></>;
  return (
    <div className="h-[100%]  rounded-md  transition-all duration-200">
      <div className="flex flex-col gap-4">
        <div className="gap-5 h-[10vw] bg-black rounded-lg text-white p-3 flex justify-center flex-col items-center">
          <img className="object-contain h-[60px]" src={icon} alt={title} />
          <span className=" text-xl">{title}</span>
        </div>
        {cards.map((item: any, index: number) => {
          return (
            <div key={title + index}>
              <Card cardInfo={item.card} count={item.count} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const TopFiveCards: FC<any> = ({ deckId }) => {
  const { cardState, workshop } = useWorkshop();

  if (!deckId) return <></>;

  const collections = getTopCards(deckId, workshop.cards, cardState);

  return (
    <div className="flex flex-1 gap-[50px]">
      <div className="flex-1">
        <Collection
          title="You are."
          icon={collection1}
          id="collection1"
          cards={collections.collection1}
        />
      </div>
      <div className="flex-1">
        <Collection
          title="You are not."
          icon={collection2}
          id="collection2"
          cards={collections.collection2}
        />
      </div>
      <div className="flex-1">
        <Collection
          title="You are torn."
          icon={collection3}
          id="collection3"
          cards={collections.collection3}
        />
      </div>
      <div className="flex-1">
        <Collection
          title="Does not apply."
          icon={collection4}
          id="collection4"
          cards={collections.collection4}
        />
      </div>
    </div>
  );
};
