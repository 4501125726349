import React, { FC, useEffect } from "react";
import { motion, useAnimationControls } from "framer-motion";

export const Modal: FC<{
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactElement;
}> = ({ isOpen, onClose, children }) => {
  const containerControl = useAnimationControls();
  const backgroundControl = useAnimationControls();
  const innerControl = useAnimationControls();

  useEffect(() => {
    if (isOpen) {
      containerControl.start({ display: "block" }, { duration: 0 }).then(() => {
        backgroundControl.start({ opacity: 0.6 });
        innerControl.start({ opacity: 1, y: 0 });
      });
    } else {
      innerControl.start({ opacity: 0, y: 100 });
      backgroundControl.start({ opacity: 0 }).then(() => {
        containerControl.start({ display: "none" }, { duration: 0 });
      });
    }
  }, [isOpen]);

  return (
    <motion.div
      initial={{ display: "none" }}
      animate={containerControl}
      className="fixed top-0 left-0 z-[1000]"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={backgroundControl}
        className="absolute top-0 left-0 z-[1000] bg-black w-[100vw] h-[100vh] opacity-60"
      ></motion.div>
      <motion.div
        transition={{ type: "keyframes" }}
        initial={{ y: 200, opacity: 0 }}
        animate={innerControl}
        onClick={onClose}
        className="z-[20000] absolute top-0 left-0  w-[100vw] h-[100vh] flex items-start justify-center"
      >
        <div className="pt-[100px]" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </motion.div>
    </motion.div>
  );
};
