import { FC } from "react";
import { ErrorBoundary } from "react-error-boundary";

export const ViewWrapper: FC<{
  children?: React.ReactNode;
  title: string;
  actions?: any;
}> = ({ title, children, actions }) => {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between">
        <h1 className="text-3xl uppercase font-bold">{title}</h1>
        {actions && <div>{actions}</div>}
      </div>
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <div>{children}</div>
      </ErrorBoundary>
    </div>
  );
};
