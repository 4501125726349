import { AdminLayout, Button, ConfirmationPopup, PageHeader } from "components";
import { Plus, RotateCw } from "react-feather";
import { DeckGrid } from "./DeckGrid";
import { useDeckStore } from "store";
import { useEffect, useState } from "react";
import { DeckForm } from "./DeckForm";

export const AdminDecks = () => {
  const [showForm, setShowForm] = useState(false);
  const { loaded, getDecks } = useDeckStore((state: any) => state);

  const loadDecks = () => {
    getDecks({ onSuccess: () => {}, onError: () => {} });
  };

  useEffect(() => {
    if (loaded) return;
    loadDecks();
  }, []);

  return (
    <AdminLayout loading={false}>
      <PageHeader
        title="Decks"
        buttons={[
          <Button
            key="reload"
            title="Reload"
            onClick={loadDecks}
            icon={<RotateCw size="16px" />}
          />,
          <Button
            key="create"
            title="Create New"
            onClick={() => setShowForm(true)}
            icon={<Plus />}
          />,
        ]}
      >
        <DeckForm
          data={showForm}
          isOpen={Boolean(showForm)}
          onClose={() => setShowForm(false)}
        />
        <DeckGrid onUpdate={(data: any) => setShowForm(data)} />
      </PageHeader>
    </AdminLayout>
  );
};
