import { Button } from "components";
import { AlertBox } from "components/AlertBox";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useDeckStore } from "store";

const Deck: FC<{ deck: any; onUpdate: (data: any) => void }> = ({
  deck,
  onUpdate,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-2 border border-black rounded-md p-3">
      <div className="text-sm">
        ID: <span className="uppercase italic">{deck.id || "-"}</span>
      </div>
      <h2 className="text-2xl uppercase font-bold">{deck.name}</h2>
      <div className="flex gap-2">
        <Button
          title="Open"
          size="sm"
          onClick={() => navigate(`/admin/decks/${deck._id}`)}
        />
        <Button onClick={() => onUpdate(deck)} title="Update" size="sm" />
      </div>
    </div>
  );
};

export const DeckGrid: FC<{ onUpdate: (data: any) => void }> = ({
  onUpdate,
}) => {
  const { decks } = useDeckStore((state: any) => state);

  if (decks.length == 0) {
    return (
      <AlertBox
        title="No decks available"
        subtitle="Please create decks to view them here"
      />
    );
  }

  return (
    <div className="grid grid-rows-2 grid-cols-5 gap-3 w-[100%]">
      {decks.map((deck: any, index: number) => (
        <div key={"deck-" + index}>
          <Deck deck={deck} onUpdate={onUpdate} />
        </div>
      ))}
    </div>
  );
};
