import { Loader } from "react-feather";

export const LoadingView = () => {
  return (
    <div className="flex flex-col items-center justify-center h-[70vh]">
      <Loader className="animate-spin " size={"50px"} />
      <span className="font-bold">LOADING WORKSHOP</span>
    </div>
  );
};
