import { FC } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Card } from "components";
import { useWorkshop } from "modules/Workshop/workshopProvider";

const getListStyle = (isDraggingOver: any) => {
  return {
    background: isDraggingOver ? "lightgrey" : "",
  };
};

export const Collection: FC<{
  title: string;
  icon: string;
  id: string;
  cards: any;
}> = ({ title, icon, id, cards }) => {
  const { submissionState, selectedDeck } = useWorkshop();

  const submitted = submissionState.includes(selectedDeck);

  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => {
        return (
          <div
            style={getListStyle(snapshot.isDraggingOver)}
            className="h-[100%]  rounded-md  transition-all duration-200"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className=" flex flex-col gap-4">
              <div className="gap-5 h-[10vw] bg-black rounded-lg text-white p-3 flex justify-center flex-col items-center">
                <img
                  className="object-contain h-[60px]"
                  src={icon}
                  alt={title}
                />
                <span className=" text-xl">{title}</span>
              </div>
              {cards.map((item: any, index: number) => {
                if (!item) return;
                if (submitted) {
                  return <Card key={item._id} cardInfo={item} />;
                }
                return (
                  <Draggable
                    key={item._id}
                    draggableId={String(item._id)}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Card cardInfo={item} />
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};
