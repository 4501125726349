import { useWorkshop } from "modules/AdminWorkshop/workshopProvider";

import { Card } from "components";
import { FC } from "react";

const getTopCards = (deckId: number, cards: any, cardState: any) => {
  const usedCards: any = [];

  Object.values(cardState).forEach((cards: any) => {
    Object.entries(cards[deckId]).forEach(([key, value]: any) => {
      if (key != "stack" && key != "collection4") {
        usedCards.push(...value);
      }
    });
  });
  const uniqueCardIds = [...new Set(usedCards)];

  let data: any = [];

  uniqueCardIds.forEach((id) => {
    data.push({ id, count: usedCards.filter((c: any) => c === id).length });
  });

  data.sort(function (a: any, b: any) {
    return b.count - a.count;
  });

  data = data.splice(0, 5);

  data.forEach((d: any, index: number) => {
    const card = cards.find((c: any) => c._id == d.id);
    data[index].card = card;
  });

  return data;
};

export const OverallCards: FC<any> = ({ deckId }) => {
  const { cardState, workshop } = useWorkshop();

  const cards = getTopCards(deckId, workshop.cards, cardState);

  return (
    <div className="flex-1 gap-[10px] grid grid-rows-2 grid-cols-4">
      {cards.map((card: any) => (
        <div key={card.id}>
          <Card cardInfo={card.card} count={card.count} />
        </div>
      ))}
    </div>
  );
};
