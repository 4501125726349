import { apiCaller } from "utils";
import { create } from "zustand";
import { ActionArgs } from "store";

export const useDeckStore = create((set) => ({
  decks: [],
  loaded: false,
  loading: true,
  expire: () => {
    set((state: any) => {
      return { ...state, loaded: false };
    });
  },
  getDecks: async ({ onSuccess, onError }: ActionArgs) => {
    set((state: any) => ({ ...state, loading: true }));
    try {
      const {
        data: { rows },
      } = await apiCaller.get("/decks");
      set((state: any) => ({
        ...state,
        decks: rows,
        loading: false,
        loaded: true,
      }));
      onSuccess && onSuccess();
    } catch (err) {
      set((state: any) => ({ ...state, loading: false }));
      onError && onError(err);
    }
  },
  createDeck: async ({ values, onSuccess, onError }: ActionArgs) => {
    set((state: any) => ({ ...state, loading: true }));
    try {
      const {
        data: { data },
      } = await apiCaller.post("/decks", values);
      set((state: any) => ({
        ...state,
        decks: [data, ...state.decks],
        loading: false,
      }));
      onSuccess && onSuccess();
    } catch (err) {
      set((state: any) => ({ ...state, loading: false }));
      onError && onError(err);
    }
  },
  updateDeck: async ({ values, onSuccess, onError }: ActionArgs) => {
    set((state: any) => ({ ...state, loading: true }));
    try {
      const {
        data: { data },
      } = await apiCaller.put("/decks", values);
      set((state: any) => {
        const index = state.decks.findIndex(({ _id }: any) => _id == data._id);
        const decks = [...state.decks];
        decks[index] = data;
        return { ...state, decks, loading: false };
      });
      onSuccess && onSuccess();
    } catch (err) {
      set((state: any) => ({ ...state, loading: false }));
      onError && onError(err);
    }
  },
  removeDeck: async (id: string) => {
    set((state: any) => {
      const newDecks = state.decks.filter(({ _id }: any) => _id != id);
      return { ...state, decks: newDecks };
    });
  },
}));
