import { Modal } from "components";
import { Button } from "components/Button";
import { FC } from "react";
import { X } from "react-feather";

export const ConfirmationPopup: FC<{
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactElement;
  title: string;
  confirmationText: string;
  onConfirm: () => void;
}> = ({ isOpen, onClose, children, title, confirmationText, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="bg-white p-5 rounded-lg w-[400px] flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <h3 className="text-2xl uppercase ">{title}</h3>
          <button onClick={onClose}>
            <X />
          </button>
        </div>
        <span>{children}</span>
        <div className="flex gap-2 justify-end">
          <Button title={confirmationText} onClick={onConfirm} />
          <Button title="Cancel" onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
};
