import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";

import { AdminLayout, Button, FormikInput } from "components";
import { extractError } from "utils";
import { useAuthStore } from "store";

const loginSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const AdminLogin = () => {
  const { login } = useAuthStore((state: any) => state);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      login({
        values,
        onSuccess: () => {
          navigate("/admin");
        },
        onError: (err: any) => {
          toast.error(extractError(err));
        },
      });
    },
  });

  return (
    <AdminLayout hideNav>
      <div className="flex justify-center h-[100vh] w-[100vw] pt-[100px] items-start">
        <div className="w-[400px] border-black border p-7 px-5 rounded-lg flex flex-col gap-5 items-center">
          <h1 className="text-4xl font-bold">Login</h1>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col w-full gap-3"
          >
            <FormikInput
              placeholder="Email Address"
              name="email"
              formik={formik}
            />
            <FormikInput
              placeholder="Password"
              type="password"
              name="password"
              formik={formik}
            />
            <div className="flex justify-center">
              <Button title="LOGIN ACCOUNT" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};
