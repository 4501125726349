import { useLocation } from "react-router-dom";
import { WorkshopProvider, useWorkshop } from "./workshopProvider";
import { WorkshopLayout } from "./WorkshopLayout";
import { MessageView } from "./MiniViews";
import { CardTable } from "./CardTable";
import { FeedbackPopup } from "./FeedbackPopup";

export const WorkshopInner = () => {
  const { invalid, loading, connection, workshop, isPublic } = useWorkshop();

  if (invalid) {
    return (
      <MessageView
        heading="The invitation link is invalid"
        message="The invitation link you used is invalid"
      />
    );
  }

  if (loading || !workshop) {
    return (
      <MessageView
        heading="Workshop is being loaded"
        message="Please wait while the workshop is loaded"
      />
    );
  }

  if (!isPublic) {
    return (
      <MessageView
        heading="Workshop session has not started"
        message="Please wait while the workshop session starts"
      />
    );
  }

  if (!connection.status) {
    return (
      <MessageView heading={connection.heading} message={connection.message} />
    );
  }

  return (
    <WorkshopLayout>
      <CardTable />
    </WorkshopLayout>
  );
};

export const Workshop = () => {
  const { search } = useLocation();

  return (
    <WorkshopProvider invitationLink={search}>
      <FeedbackPopup />
      <WorkshopInner />
    </WorkshopProvider>
  );
};
