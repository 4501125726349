import { FC } from "react";
import { Button, Card } from "components";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
  useWorkshop,
  useWorkshopActions,
} from "modules/Workshop/workshopProvider";

const getListStyle = (isDraggingOver: any) => {
  return {
    background: isDraggingOver ? "lightgrey" : "",
  };
};

const SubmissionCard: FC<{ progress: number }> = ({ progress }) => {
  const { submissionState, selectedDeck } = useWorkshop();
  const { submitDeck, unsubmitDeck } = useWorkshopActions();

  if (progress != 0) return <></>;

  if (submissionState.includes(selectedDeck)) {
    return (
      <div className="flex-col gap-3 border border-black rounded-md p-2 flex items-center justify-center h-[150px]">
        <div className=" text-xl">DECK HAS BEEN SUBMITTED</div>
        <div>
          <Button title="UNSUBMIT DECK" onClick={unsubmitDeck} />
        </div>
      </div>
    );
  }

  return (
    <div className=" rounded-md p-3 bg-gray-50 border border-gray-400 text-center flex flex-col gap-3">
      <p className="text-lg">ALL CARDS USED</p>
      <p className="text-sm ">
        You can submit this deck to the admin, once submitted you will not be
        able to make a change in the cards
      </p>
      <div>
        <Button title="SUBMIT DECK" onClick={submitDeck} />
      </div>
    </div>
  );
};

export const Stack: FC<{
  cards: any[];
  totalCardCount: number;
}> = ({ cards, totalCardCount }) => {
  const progress = cards.length / totalCardCount;

  return (
    <Droppable droppableId={"stack"}>
      {(provided, snapshot) => (
        <div
          style={getListStyle(snapshot.isDraggingOver)}
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="relative rounded-md h-[100%] transition-all duration-200"
        >
          <div className="relative bg-gray-200 h-[5px] rounded-lg mb-5 overflow-hidden">
            <div
              className="absolute h-full bg-black left-0 transition-all duration-200"
              style={{ width: progress * 100 + "%" }}
            ></div>
          </div>
          <SubmissionCard progress={progress} />
          {cards.map((item: any, index: number) => {
            return (
              <Draggable
                key={item._id}
                draggableId={String(item._id)}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div
                      style={{
                        transform: `rotate(${item.rotation}deg)`,
                      }}
                    >
                      <Card absolute cardInfo={item} />
                    </div>
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
