import { FC, useState, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import { Button } from "components/Button";
import { RefreshCcw, Trash } from "react-feather";
import { useSettingsStore } from "store";
import { useWindowSize } from "hooks";
import parse from "html-react-parser";
import { ErrorBoundary } from "react-error-boundary";

type CardSideType = {
  label?: string;
  bgColor: string;
  textColor: string;
  accentColor: string;
  body?: string;
  backgroundImageLink?: any;
};

const CardSide: FC<{ data: CardSideType; front?: boolean }> = ({
  data,
  front,
}) => {
  return (
    <div
      className={
        (front ? styles.front : styles.back) +
        " pointer-events-none border-2 border-grey-500 p-5 rounded-lg flex flex-col gap-3 overflow-hidden"
      }
      style={{
        color: data.textColor,
      }}
    >
      {data.backgroundImageLink && (
        <img
          className="absolute top-0 left-0 z-[-1] bg-no-repeat object-cover object-right-top h-full w-full"
          src={data.backgroundImageLink}
        />
      )}
      <div
        className="absolute top-0 left-0 w-full h-full z-[-20]"
        style={{
          backgroundColor: data.bgColor,
        }}
      ></div>
      <div
        className=" border-b-2"
        style={{ borderColor: data.accentColor }}
      ></div>
      <div className="flex-1 flex flex-col gap-1">
        <div className="text-2xl select-none ">{data.label}</div>
        <div className="text-md select-none">
          <ErrorBoundary fallbackRender={() => <>{data.body}</>}>
            {parse(data.body || "")}
          </ErrorBoundary>
        </div>
      </div>
      <div
        className=" border-b"
        style={{ borderColor: data.accentColor }}
      ></div>
    </div>
  );
};

export const Card: FC<{
  onDelete?: (card: any) => void;
  onUpdate?: (card: any) => void;
  showAdminButton?: boolean;
  count?: number;
  cardInfo: {
    id: string | number;
    front: CardSideType;
    back: CardSideType;
  };
  absolute?: boolean;
  movement?: boolean;
  flipped?: boolean;
}> = ({
  cardInfo,
  absolute,
  showAdminButton,
  onDelete,
  onUpdate,
  count,
  flipped: superFlipped,
}) => {
  const [flipped, setFlipped] = useState(false);
  const cardRef: any = useRef();
  const { cardRatio } = useSettingsStore((state: any) => state.settings);
  const [height, setHeight] = useState(200);
  const [dimension, setDimension] = useState([2, 3]);
  const windowSize = useWindowSize();
  const handleWindowResize = () => {
    const width = cardRef.current.offsetWidth;
    const height = (dimension[0] * width) / dimension[1];
    setHeight(height);
  };

  useEffect(() => {
    handleWindowResize();
  }, [windowSize]);

  useEffect(() => {
    try {
      const [z1, z2] = cardRatio.split("x");
      setDimension([z1, z2]);
    } catch {}
  }, [cardRatio]);

  return (
    <div
      className={
        (absolute ? "absolute w-[100%]" : "relative") + " " + styles.cardHolder
      }
    >
      {showAdminButton && (
        <div
          className={
            "absolute z-[10000] bottom-0 right-0 flex gap-2 p-2 " +
            styles.adminButtons
          }
        >
          <Button
            title="Update"
            onClick={() => onUpdate && onUpdate(cardInfo)}
            className="bg-white"
            size="sm"
            icon={<RefreshCcw size="16px" />}
          />
          <Button
            title="Delete"
            onClick={() => onDelete && onDelete(cardInfo)}
            className="bg-white"
            size="sm"
            icon={<Trash size="16px" />}
          />
        </div>
      )}
      {Boolean(count) && count != 0 && (
        <div className={"absolute z-[10000] bottom-0 right-0 flex gap-2 p-2 "}>
          <div className="rounded-full bg-black w-[40px] h-[40px] flex items-center justify-center border-2 border-white">
            <span className="font-bold text-xl text-white">{count}</span>
          </div>
        </div>
      )}
      <div
        onClick={() => setFlipped(!flipped)}
        ref={cardRef}
        style={{ height }}
        className={"bg-transparent cursor-grab " + styles.card}
      >
        <div
          className={styles.innerCard}
          style={{
            transform: (superFlipped !== undefined ? superFlipped : flipped)
              ? "rotateY(180deg)"
              : "rotateY(0deg)",
          }}
        >
          <CardSide data={cardInfo.front} front />
          <CardSide data={cardInfo.back} />
        </div>
      </div>
    </div>
  );
};
