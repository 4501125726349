import { Card } from "components";
import { FC } from "react";
import { useDeck } from "../deckProvider";
import { AlertBox } from "components/AlertBox";

export const CardsList: FC<{
  onUpdate: (data: any) => void;
  onDelete: (data: any) => void;
}> = ({ onUpdate, onDelete }) => {
  const { cards } = useDeck();

  if (cards.length == 0) {
    return (
      <AlertBox
        title="No cards available on this deck"
        subtitle="Please create cards to add them to the deck"
      />
    );
  }

  return (
    <div className="relative flex gap-3 flex-wrap">
      {cards.map((card: any, index: number) => (
        <div className="w-[400px]" key={index}>
          <Card
            cardInfo={card}
            showAdminButton
            onDelete={onDelete}
            onUpdate={onUpdate}
          />
        </div>
      ))}
    </div>
  );
};
