import { AdminLayout, Button, ConfirmationPopup, PageHeader } from "components";
import { ChevronRight, Info, Plus, RotateCw, Save, Trash } from "react-feather";
import { motion, useAnimationControls } from "framer-motion";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { DeckProvider, useDeck, useDeckActions } from "./deckProvider";
import { DeckForm } from "modules/AdminDecks/DeckForm";
import { CardForm } from "./CardForm";
import { CardsList } from "./CardsList";
import { DeckInfo } from "./DeckInfo";

export const AdminDeckInner = () => {
  const { deck, loading } = useDeck();
  const { loadDeck, updateDeck, deleteCard, deleteDeck } = useDeckActions();

  const control = useAnimationControls();

  const [showDeckForm, setShowDeckForm] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [showCardDelete, setShowCardDelete] = useState<any>(false);
  const [showDeckDelete, setShowDeckDelete] = useState<any>(false);
  const [showInfo, setShowInfo] = useState<any>(false);

  useEffect(() => {
    if (showCardForm) control.start({ width: 400 });
    else control.start({ width: 0 });
  }, [showCardForm]);

  return (
    <AdminLayout loading={loading}>
      <div className="flex gap-5 h-full">
        <div className="flex-1">
          <PageHeader
            title={
              <div className="flex items-center gap-2">
                <span className="text-3xl">
                  <Link to={"/admin/decks"}>Decks</Link>
                </span>
                <ChevronRight size={30} />
                <h1 className="text-3xl font-bold">{deck.name}</h1>
                <span className="text-xl text-gray-500">[{deck.id}]</span>
              </div>
            }
            buttons={[
              <Button
                key="reload"
                title="Reload"
                onClick={loadDeck}
                icon={<RotateCw size="16px" />}
              />,
              <Button
                key="info"
                title="Update Info"
                onClick={() => setShowInfo(true)}
                icon={<Info size="16px" />}
              />,
              <Button
                key="update"
                title="Update Deck"
                onClick={() => setShowDeckForm(deck)}
                icon={<Save size="16px" />}
              />,
              <Button
                key="delete"
                title="Delete Deck"
                onClick={() => setShowDeckDelete(true)}
                icon={<Trash size="16px" />}
              />,
              <Button
                key="create"
                title={showCardForm ? "Hide Card Form" : "Create Card"}
                onClick={() => setShowCardForm(!showCardForm)}
                icon={<Plus size="16px" />}
              />,
            ]}
          >
            <ConfirmationPopup
              isOpen={showCardDelete}
              onClose={() => setShowCardDelete(false)}
              confirmationText="Delete"
              title="Delete Card"
              onConfirm={() =>
                deleteCard({
                  values: showCardDelete,
                  onSuccess: () => {
                    setShowCardDelete(false);
                    setShowCardForm(false);
                  },
                })
              }
            >
              {showCardDelete && (
                <div>
                  Are you sure you want to delete the card{" "}
                  <b>
                    {showCardDelete.front.label}/{showCardDelete.back.label}
                  </b>
                  ?
                </div>
              )}
            </ConfirmationPopup>
            <ConfirmationPopup
              isOpen={showDeckDelete}
              onClose={() => setShowDeckDelete(false)}
              confirmationText="Delete"
              title="Delete Deck"
              onConfirm={deleteDeck}
            >
              <div>
                Are you sure you want to delete the deck? This will delete all
                the cards in the deck as well.
              </div>
            </ConfirmationPopup>
            <DeckForm
              data={deck}
              isOpen={showDeckForm}
              onClose={() => setShowDeckForm(false)}
              onSubmit={(values) => {
                updateDeck({ values, onSuccess: () => setShowDeckForm(false) });
              }}
            />
            <DeckInfo isOpen={showInfo} onClose={() => setShowInfo(false)} />
            <CardsList
              onUpdate={setShowCardForm}
              onDelete={(data) => setShowCardDelete(data)}
            />
          </PageHeader>
        </div>
        <motion.div
          animate={control}
          className="w-0 overflow-hidden h-full relative"
        >
          <CardForm
            updateData={showCardForm}
            onClose={() => setShowCardForm(false)}
          />
        </motion.div>
      </div>
    </AdminLayout>
  );
};

export const AdminDeck = () => {
  const { id } = useParams();
  return (
    <DeckProvider id={id}>
      <AdminDeckInner />
    </DeckProvider>
  );
};
