import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { toast } from "react-hot-toast";
import { apiCaller, extractError, socket } from "utils";
import { constructCardState } from "./helpers";
import random from "random";
import { useSettingsStore } from "store";
import {
  FORCE_RELOAD,
  ROOM_DISCONNECTED,
  UPDATE_WORKSHOP_STATE,
  WORKSHOP_JOINED,
  WORKSHOP_UPDATED,
} from "socketConstants";

const WorkshopContext = createContext<any>({});
const WorkshopActionsContext = createContext<any>({});
export const useWorkshop = () => useContext(WorkshopContext);
export const useWorkshopActions = () => useContext(WorkshopActionsContext);

export const WorkshopProvider: React.FC<{
  children: ReactNode;
  invitationLink: string;
}> = ({ children, invitationLink }) => {
  const [workshop, setWorkshop] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [isPublic, setPublic] = useState(false);

  const [participantCount, setParticipantCount] = useState(0);

  const [connection, setConnection] = useState<{
    status: boolean;
    heading?: string;
    message?: string;
  }>({ status: false, heading: "", message: "" });

  const [invalid, setInvalid] = useState(false);

  const [cards, setCards] = useState([]);
  const [decks, setDecks] = useState<any[]>([]);
  const [cardState, setCardState] = useState<any>({});
  const [submissionState, setSubmissionState] = useState<any>({});
  const [feedback, setFeedback] = useState("");

  const [selectedDeck, setSelectedDeck] = useState<any>(null);

  // get the token and the workshopId from the url
  const getAuthObject = () => {
    const params = new URLSearchParams(invitationLink);
    const token = params.get("token");
    const workshopId = params.get("workshopId");
    if (!token || !workshopId) throw false;
    return { token, workshopId };
  };

  // connects to the server and gets the deck/cards data
  const validateConnection = async () => {
    const authObject = getAuthObject();
    const {
      data: {
        data: { cards, decks, settings, ...workshop },
      },
    } = await apiCaller.post(`/workshops/join`, authObject);
    return { cards, decks, settings, workshop, ...authObject };
  };

  // sets all the data and then requests joining via socket
  const join = async () => {
    setLoading(true);
    try {
      const { cards, decks, settings, workshop, token, workshopId } =
        await validateConnection();

      useSettingsStore.setState(() => ({ settings }));

      if (workshop.description) {
        window.open("/workshop-description" + invitationLink);
      }

      setWorkshop(workshop);
      setPublic(workshop.public);

      setDecks(decks);
      if (decks.length > 0) {
        setSelectedDeck(decks[0]._id);
      }

      cards.forEach((card: any) => {
        card.rotation = random.float(-3, 5);
      });
      setCards(cards);

      socket.emit("REQUEST_WORKSHOP_PUBLIC_ADMISSION", { workshopId, token });
    } catch (err) {
      setInvalid(true);
      toast.error("Invalid or expired invitation link");
      setLoading(false);
    }
  };

  // submits the deck
  const submitDeck = async () => {
    setLoading(true);
    try {
      const authObject = getAuthObject();
      await apiCaller.post("/workshops/submitDeck", {
        deckId: selectedDeck,
        ...authObject,
      });
      setSubmissionState([selectedDeck, ...submissionState]);
    } catch (err) {
      toast.error(extractError(err));
    }
    setLoading(false);
  };

  // unsubmit the deck
  const unsubmitDeck = async () => {
    setLoading(true);
    try {
      const authObject = getAuthObject();
      await apiCaller.post("/workshops/unsubmitDeck", {
        deckId: selectedDeck,
        ...authObject,
      });
      const newSubmissionState = [...submissionState].filter(
        (s) => s !== selectedDeck
      );
      setSubmissionState(newSubmissionState);
    } catch (err) {
      toast.error(extractError(err));
    }
    setLoading(false);
  };

  const updateCardState = (cards: any) => {
    const newCardState = { ...cardState };
    newCardState[String(selectedDeck)] = cards;
    setCardState(newCardState);
    return newCardState;
  };

  const updateWorkshopState = (decks: any) => {
    const cardState: any = {};
    Object.keys(decks).forEach((deckId) => {
      let data: any = {};

      Object.keys(decks[deckId]).forEach((id) => {
        const deck = decks[deckId];
        data[id] = deck[id].map(({ _id }: any) => _id);
      });
      cardState[deckId] = data;
    });
    if (workshop) {
      socket.emit(UPDATE_WORKSHOP_STATE, {
        workshopId: (workshop as any)["_id"],
        deckId: selectedDeck,
        cardState: cardState[selectedDeck],
      });
    }
  };

  useEffect(() => {
    join();
  }, []);

  useEffect(() => {
    // on disconnect
    socket.on(ROOM_DISCONNECTED, ({ heading, message }) => {
      setConnection({ status: false, heading, message });
    });

    // on connection
    socket.on(WORKSHOP_JOINED, ({ cardState, submissionState, feedback }) => {
      if (feedback) setFeedback(feedback);
      const _cardState = constructCardState(cards, cardState);
      setCardState(_cardState);
      setSubmissionState(submissionState);
      setConnection({ status: true });
      setLoading(false);
    });

    // when workshop is updated
    socket.on(WORKSHOP_UPDATED, (data) => {
      if (data.feedback) setFeedback(data.feedback);
      setParticipantCount(data.participantCount);
      setPublic(data.public);
    });

    // when the server demands reload
    socket.on(FORCE_RELOAD, (data) => {
      setLoading(true);
      window.location.reload();
    });

    return () => {
      socket.off(ROOM_DISCONNECTED);
      socket.off(WORKSHOP_JOINED);
      socket.off(WORKSHOP_UPDATED);
      socket.off(FORCE_RELOAD);
    };
  }, [cards, decks, workshop]);

  return (
    <WorkshopContext.Provider
      value={{
        invalid,
        loading,
        workshop,
        connection,
        participantCount,
        cards,
        decks,
        cardState,
        selectedDeck,
        isPublic,
        submissionState,
        feedback,
      }}
    >
      <WorkshopActionsContext.Provider
        value={{
          setSubmissionState,
          updateWorkshopState,
          setSelectedDeck,
          updateCardState,
          submitDeck,
          unsubmitDeck,
        }}
      >
        {children}
      </WorkshopActionsContext.Provider>
    </WorkshopContext.Provider>
  );
};
