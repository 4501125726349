import { Button, FormikInput } from "components";
import { useFormik } from "formik";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useAuthStore } from "store";
import { apiCaller, extractError } from "utils";
import * as yup from "yup";

const schema = yup.object({
  currentPassword: yup.string().required("The current password is required"),
  newPassword: yup.string().required("The new password is required"),
  newPasswordRepeated: yup.string().required("Please repeat the new password"),
});

export const PasswordForm = () => {
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordRepeated: "",
    },
    onSubmit: async (values, { setFieldError, resetForm }) => {
      const { newPassword, newPasswordRepeated } = values;
      if (newPassword !== newPasswordRepeated) {
        return setFieldError(
          "newPasswordRepeated",
          "Doesn't match the new password"
        );
      }
      try {
        await apiCaller.post("/profile/password", values);
        toast.success("Password has been updated");
        resetForm();
      } catch (err) {
        toast.error(extractError(err));
      }
    },
    validationSchema: schema,
  });

  return (
    <div className="border border-black rounded-md p-3">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <span className="uppercase font-bold">Update your Password</span>
        <FormikInput
          type="password"
          label="Current Password"
          name="currentPassword"
          formik={formik}
          placeholder="Current Password"
        />
        <FormikInput
          type="password"
          label="New Password"
          name="newPassword"
          formik={formik}
          placeholder="New Password"
        />
        <FormikInput
          type="password"
          label="New Password (Repeat)"
          name="newPasswordRepeated"
          formik={formik}
          placeholder="New Password (Repeat)"
        />
        <div>
          <Button title="Update Password" type="submit" />
        </div>
      </form>
    </div>
  );
};
