import { FC, useEffect } from "react";
import { FormikInput, Modal } from "components";
import { X } from "react-feather";
import { useFormik } from "formik";
import { Button } from "components/Button";
import { extractError } from "utils";
import { useWorkshopStore } from "store";
import * as yup from "yup";
import { toast } from "react-hot-toast";

const schema = yup.object({
  name: yup.string().required("The workshop name is required"),
});

export const WorkshopCreateForm: FC<{
  switchToActive: () => void;
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose, switchToActive }) => {
  const { createWorkshop, getWorkshops } = useWorkshopStore(
    (state: any) => state
  );

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      createWorkshop({
        values,
        onSuccess: () => {
          onClose();
          switchToActive();
          getWorkshops({
            values: { archived: false },
            onError: (err: any) => {
              toast.error(extractError(err));
            },
          });
        },
      });
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="bg-white w-[500px] p-4 px-6 rounded-lg">
        <div className="flex justify-between pb-3">
          <h3 className="uppercase text-xl font-bold">Create New Workshop</h3>
          <button onClick={onClose}>
            <X />
          </button>
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
          <FormikInput
            formik={formik}
            name="name"
            placeholder="Workshop Name"
          />
          <div>
            <Button title="CREATE" type="submit" size="md" />
          </div>
        </form>
      </div>
    </Modal>
  );
};
