import dayjs from "dayjs";
import { ViewWrapper } from "../../components";
import { useWorkshop } from "../../workshopProvider";
import { OverviewActions } from "./Actions";

export const Overview = () => {
  const { workshop } = useWorkshop();
  const dataList = [
    { id: "Workshop ID", value: workshop._id },
    { id: "Workshop Name", value: workshop.name },
    { id: "Invited Participants", value: workshop.participants.length },
    {
      id: "Created At",
      value: dayjs(workshop.createdAt).format("DD MMMM YYYY"),
    },
    {
      id: "Decks",
      value: workshop.decks.map(({ name }: any) => name).join(", ") || "-",
    },
  ];

  return (
    <ViewWrapper title="Information">
      <table>
        <tbody>
          {dataList.map(({ id, value }) => {
            return (
              <tr key={id}>
                <td className="w-[200px]">{id}</td>
                <td className="font-bold">{value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className=" border-b border-black pt-5 mb-5"></div>
      <OverviewActions />
    </ViewWrapper>
  );
};
