import { FormikInput } from "components";
import { FC, useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { ChromePicker } from "react-color";
import { X } from "react-feather";
import { ImageInput } from "./ImageInput";

const ColorBox: FC<{
  label: string;
  value: string;
  onChange: (value: string) => void;
}> = ({ label, value, onChange }) => {
  const [show, setShow] = useState(false);
  const holderRef: any = useRef();
  const colorPickerRef: any = useRef();
  const [position, setPosition] = useState([0, 0]);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <a
      className="relative w-auto "
      onClick={(e) => {
        e.stopPropagation();
        if (!show) {
          setPosition([e.pageY, window.innerWidth - e.pageX]);
          setShow(true);
        }
      }}
    >
      <div
        ref={holderRef}
        className="cursor-pointer flex items-center gap-2 p-2 py-1 rounded-md border border-grey"
      >
        <span>{label}</span>
        <div
          className="h-[30px] w-[30px] rounded-md border border-slate-300 relative"
          style={{ background: value }}
        >
          {show &&
            createPortal(
              <div
                ref={colorPickerRef}
                className="z-[1000] absolute  color-picker"
                style={{ top: position[0], right: position[1] }}
              >
                <ChromePicker
                  color={value}
                  onChange={({ hex }) => {
                    onChange(hex);
                  }}
                />
              </div>,
              document.body
            )}
        </div>
      </div>
    </a>
  );
};

export const SideForm: FC<{
  title: string;
  formik: any;
  setImage: (data: any) => void;
  show: boolean;
}> = ({ title, formik, setImage, show }) => {
  return (
    <div
      style={{ display: show ? "flex" : "none" }}
      className="border border-black rounded-md p-3 pt-5 relative min-h-[50px] flex flex-col gap-3"
    >
      <FormikInput
        name={title + ".label"}
        placeholder="Label"
        formik={formik}
        value={formik.values[title].label}
        error={formik.errors[title] && formik.errors[title].label}
      />
      <textarea
        value={formik.values[title].body}
        name={title + ".body"}
        onChange={formik.handleChange}
        placeholder="Body"
        className="border border-gray-400 rounded-md w-full p-1 px-3 resize-none"
        rows={3}
      />
      <div className="flex gap-2 flex-wrap ">
        <ColorBox
          label="BG"
          value={formik.values[title].bgColor}
          onChange={(value) => formik.setFieldValue(title + ".bgColor", value)}
        />
        <ColorBox
          label="Accent"
          value={formik.values[title].accentColor}
          onChange={(value) =>
            formik.setFieldValue(title + ".accentColor", value)
          }
        />
        <ColorBox
          label="Text"
          value={formik.values[title].textColor}
          onChange={(value) =>
            formik.setFieldValue(title + ".textColor", value)
          }
        />
      </div>
      <ImageInput formik={formik} title={title} setImage={setImage} />
    </div>
  );
};
