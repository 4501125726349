import { FC, useEffect } from "react";
import { FormikInput, Modal } from "components";
import { X } from "react-feather";
import { useFormik } from "formik";
import { Button } from "components/Button";
import { extractError } from "utils";
import { useDeckStore } from "store";
import * as yup from "yup";
import { toast } from "react-hot-toast";

const schema = yup.object({
  id: yup.string().required("The deck ID is required"),
  name: yup.string().required("The deck name is required"),
});

export const DeckForm: FC<{
  data: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (values: any) => void;
}> = ({ isOpen, onClose, data, onSubmit: customOnSubmit }) => {
  const forUpdate = typeof data != "boolean";
  const { createDeck, updateDeck } = useDeckStore((state: any) => state);

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (customOnSubmit) customOnSubmit(values);
      else {
        if (forUpdate) {
          updateDeck({
            values,
            onSuccess: () => {
              onClose();
              toast.success("Deck has been updated");
            },
            onError: (err: any) => {
              toast.error(extractError(err));
            },
          });
        } else {
          createDeck({
            values,
            onSuccess: () => {
              onClose();
              toast.success("Deck has been created");
            },
            onError: (err: any) => {
              toast.error(extractError(err));
            },
          });
        }
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
    if (forUpdate) {
      formik.setValues(data);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="bg-white w-[500px] p-4 px-6 rounded-lg">
        <div className="flex justify-between pb-3">
          <h3 className="uppercase text-xl font-bold">
            {forUpdate ? "Update" : "Create"} Deck
          </h3>
          <button onClick={onClose}>
            <X />
          </button>
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
          <FormikInput formik={formik} name="id" placeholder="Deck ID" />
          <FormikInput formik={formik} name="name" placeholder="Deck Name" />
          <div>
            <Button
              title={forUpdate ? "UPDATE" : "CREATE"}
              type="submit"
              size="md"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
