import { Button, Card } from "components";
import { SideForm } from "./SideForm";
import { useFormik } from "formik";
import * as yup from "yup";
import { FC, useEffect, useState } from "react";
import { useCardStore } from "store";
import { toast } from "react-hot-toast";
import { extractError } from "utils";
import { X } from "react-feather";
import { SideSelector } from "./SideSelector";
import { useDeckActions } from "../deckProvider";

const INITIAL_VALUES = {
  front: {
    label: "",
    body: "",
    accentColor: "black",
    textColor: "black",
    bgColor: "white",
  },
  back: {
    label: "",
    body: "",
    accentColor: "white",
    textColor: "white",
    bgColor: "black",
  },
};

const cardSchema = yup.object({
  front: yup.object({
    label: yup.string(),
    body: yup.string(),
  }),
  back: yup.object({
    label: yup.string(),
    body: yup.string(),
  }),
});

export const CardForm: FC<{ updateData: any; onClose: () => void }> = ({
  updateData,
  onClose,
}) => {
  const createMode = typeof updateData == "boolean";
  const [side, setSide] = useState<"front" | "back">("front");
  const { createCard, updateCard } = useDeckActions();

  const [images, setImages] = useState<any>({
    front: null,
    back: null,
  });

  useEffect(() => {
    setSide("front");
    setImages({
      front: null,
      back: null,
    });
  }, [updateData]);

  const formik = useFormik({
    initialValues: createMode ? INITIAL_VALUES : updateData,
    enableReinitialize: true,
    validationSchema: cardSchema,
    onSubmit: (values) => {
      if (createMode) {
        createCard({
          values,
          onSuccess: () => {
            formik.resetForm();
            onClose();
          },
        });
      } else {
        updateCard({
          values,
          onSuccess: () => {
            formik.resetForm();
            onClose();
          },
        });
      }
    },
  });

  return (
    <div className="border-l border-black  w-[400px] px-4 h-full absolute overflow-scroll">
      <div className="flex items-start justify-between pb-5">
        <h2 className="text-xl font-bold">
          {createMode ? "Create New" : "Update"} Card
        </h2>
        <button onClick={onClose}>
          <X />
        </button>
      </div>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5">
        <Card
          cardInfo={(() => {
            const data = {
              id: "",
              front: { ...formik.values.front },
              back: { ...formik.values.back },
            };

            if (images && images.front) {
              data.front.backgroundImageLink = images.front;
            }
            if (images && images.back) {
              data.back.backgroundImageLink = images.back;
            }

            if (formik.values.front.deleteBackgroundImage) {
              data.front.backgroundImageLink = null;
            }
            if (formik.values.back.deleteBackgroundImage) {
              data.back.backgroundImageLink = null;
            }

            return data;
          })()}
          flipped={side == "back"}
        />
        <SideSelector side={side} setSide={setSide} />
        <SideForm
          show={side == "front"}
          title="front"
          formik={formik}
          setImage={(data: any) => setImages({ ...images, front: data })}
        />
        <SideForm
          show={side == "back"}
          title="back"
          formik={formik}
          setImage={(data: any) => setImages({ ...images, back: data })}
        />
        <div className="flex gap-2">
          <Button type="submit" title={createMode ? "Create" : "Update"} />
        </div>
      </form>
    </div>
  );
};
