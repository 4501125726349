import { apiCaller } from "utils";
import { create } from "zustand";
import { ActionArgs } from "store";

export const useWorkshopStore = create((set) => ({
  workshops: [],
  loading: false,
  loaded: false,
  archived: false,
  expire: () => {
    set((state: any) => {
      return { ...state, loaded: false };
    });
  },
  getWorkshops: async ({ values, onSuccess, onError }: ActionArgs) => {
    set((state: any) => ({ ...state, loading: true }));
    try {
      const {
        data: { rows },
      } = await apiCaller.get("/workshops?archived=" + values.archived);
      set((state: any) => ({
        ...state,
        workshops: rows,
        loading: false,
        loaded: true,
        archived: values.archived,
      }));
      onSuccess && onSuccess();
    } catch (err) {
      set((state: any) => ({ ...state, loading: false }));
      onError && onError(err);
    }
  },
  createWorkshop: async ({ values, onSuccess, onError }: ActionArgs) => {
    set((state: any) => ({ ...state, loading: true }));
    try {
      await apiCaller.post("/workshops", values);
      onSuccess && onSuccess();
    } catch (err) {
      onError && onError(err);
    }
    set((state: any) => ({ ...state, loading: false }));
  },
  setWorkshops: (workshops: any) => set(() => ({ workshops })),
  addWorkshop: (workshop: any) =>
    set((state: any) => ({ workshops: [workshop, ...state.workshops] })),
}));
