import { FC } from "react";

const Button: FC<{ title: string; onClick: () => void; active: boolean }> = ({
  title,
  active,
  onClick,
}) => {
  return (
    <button
      type="button"
      className={
        "flex-1 border border-black  p-1 rounded-md transition-all duration-200 " +
        (active ? " bg-black text-white" : "bg-white text-black")
      }
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export const SideSelector: FC<{
  side: string;
  setSide: (side: "front" | "back") => void;
}> = ({ side, setSide }) => {
  return (
    <div className="flex gap-2">
      <Button
        title="FRONT"
        onClick={() => setSide("front")}
        active={side === "front"}
      />
      <Button
        title="BACK"
        onClick={() => setSide("back")}
        active={side === "back"}
      />
    </div>
  );
};
