import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthStore } from "store";

const ListItem: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <li
      className="relative cursor-pointer before:contents-['']
    before:absolute before:h-[2px] before:w-0 before:bg-white
    before:bottom-0 hover:before:w-full before:transition-all before:duration-200"
    >
      {children}
    </li>
  );
};

export const Header = () => {
  const { logout } = useAuthStore((state: any) => state);
  const navigate = useNavigate();

  return (
    <div className="m-[20px] flex flex-row justify-between ">
      <nav className="bg-black rounded-xl w-full p-4 px-8 text-white flex items-center justify-between">
        <div className="flex item-start gap-2">
          <h1 className="font-bold text-3xl">BrandSoul Disruption Workshop</h1>
          <span>TM</span>
        </div>

        <ul className="flex gap-5 text-lg">
          <ListItem>
            <Link to={"/admin/workshops"}>Workshops</Link>
          </ListItem>
          <ListItem>
            <Link to={"/admin/decks"}>Decks</Link>
          </ListItem>
          <ListItem>
            <Link to={"/admin/settings"}>Settings</Link>
          </ListItem>
          <ListItem>
            <Link to={"/admin/profile"}>Profile</Link>
          </ListItem>
          <ListItem>
            <a
              onClick={() => {
                logout({
                  onSuccess: () => {
                    navigate("/admin/login");
                  },
                  onError: () => {},
                });
              }}
            >
              Logout
            </a>
          </ListItem>
        </ul>
      </nav>
    </div>
  );
};
