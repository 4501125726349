import { Button, ConfirmationPopup, Modal } from "components";
import { AlertBox } from "components/AlertBox";
import { useFormik } from "formik";
import { useWorkshop, useWorkshopActions } from "../../workshopProvider";
import { Eye, Link, Mail, Speaker, Trash, X } from "react-feather";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { LiveView, ViewWrapper } from "../../components";
import { FeedbackBox } from "./FeedbackBox";

const Form = () => {
  const { addParticipants } = useWorkshopActions();

  const formik = useFormik({
    initialValues: { emails: "" },
    onSubmit: ({ emails }) => {
      addParticipants({ emails, onSuccess: () => formik.resetForm() });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <textarea
        name="emails"
        value={formik.values.emails}
        onChange={formik.handleChange}
        className="border border-gray-400  rounded-md w-full resize-none p-2"
        placeholder="Add the emails of the participant, separated by commas"
        rows={4}
      />
      <Button type="submit" title="Add Participants" className="mt-2" />
    </form>
  );
};

const Table = () => {
  const {
    workshop: { _id, participants },
  } = useWorkshop();
  const [email, setEmail] = useState<any>(false);
  const [showDelete, setShowDelete] = useState<any>(false);
  const [showFeedback, setShowFeedback] = useState<any>(false);
  const { removeParticipants } = useWorkshopActions();
  const { workshop, participantState } = useWorkshop();

  return (
    <>
      <LiveView email={email} onClose={() => setEmail(false)} />
      <FeedbackBox
        isOpen={showFeedback}
        onClose={() => setShowFeedback(false)}
      />
      <ConfirmationPopup
        isOpen={Boolean(showDelete)}
        onClose={() => setShowDelete(false)}
        title="Remove Participant"
        confirmationText="REMOVE"
        onConfirm={() => {
          removeParticipants({
            email: showDelete.email,
            onSuccess: () => setShowDelete(false),
          });
        }}
      >
        <span>
          {" "}
          Are you sure you want to remove the participant with the email:{" "}
          <b>{showDelete && `"${showDelete.email}"`}</b>?
        </span>
      </ConfirmationPopup>
      <div className="border border-grey-300 rounded-lg">
        <table className=" w-full">
          <tbody>
            <tr className=" border-b border-b-grey-300">
              <th className=" text-left p-2 px-4">Participants</th>
            </tr>
            {participants.map(
              ({ email, token }: { email: string; token: string }) => (
                <tr key={email}>
                  <td className="p-2 px-4">
                    <div className="flex gap-2 items-center ">
                      <span>{email}</span>
                      {participantState &&
                        participantState[email] &&
                        participantState[email].live &&
                        !workshop.archived && (
                          <div>
                            <span className="bg-black text-white px-2 text-xs rounded-sm ">
                              LIVE
                            </span>
                          </div>
                        )}
                      {participantState &&
                        participantState[email] &&
                        participantState[email].completed && (
                          <div>
                            <span className="bg-black text-white px-2 text-xs rounded-sm ">
                              COMPLETED
                            </span>
                          </div>
                        )}
                    </div>
                  </td>
                  <td className="p-2 px-4 w-0">
                    <div className="flex gap-2 justify-end">
                      {(workshop.archived ||
                        (participantState &&
                          participantState[email] &&
                          participantState[email].completed)) && (
                        <button
                          onClick={() => {
                            setShowFeedback(email);
                          }}
                        >
                          <Mail size={"20px"} />
                        </button>
                      )}
                      <button
                        onClick={() => {
                          setEmail(email);
                        }}
                      >
                        <Eye size={"20px"} />
                      </button>
                      {!workshop.archived && (
                        <>
                          <button
                            onClick={() => {
                              const url = `${window.location.origin}/workshop?token=${token}&workshopId=${_id}`;
                              copy(url);
                              toast.success(
                                "Invitation link has been copied to clipboard"
                              );
                            }}
                          >
                            <Link size={"20px"} />
                          </button>
                          <button
                            onClick={() => setShowDelete({ email, token })}
                          >
                            <Trash size={"20px"} />
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export const Participants = () => {
  const { workshop } = useWorkshop();

  return (
    <ViewWrapper title="Participants">
      {!workshop.locked && (
        <AlertBox
          title="Please lock the workshop to invite participants"
          subtitle="You must lock the workshop in order to invite participants"
        />
      )}
      {workshop.locked && (
        <div className="flex gap-5">
          {!workshop.archived && (
            <div className="flex-1">
              <Form />
            </div>
          )}
          <div className="flex-1">
            <Table />
          </div>
        </div>
      )}
    </ViewWrapper>
  );
};
