import { FC } from "react";
import { Info } from "react-feather";

export const AlertBox: FC<{ title: string; subtitle: string }> = ({
  title,
  subtitle,
}) => {
  return (
    <div className="border border-gray-300 w-[500px] p-4 rounded-md">
      <div className=" flex gap-2 items-center">
        <Info />
        <h3 className="text-xl">{title}</h3>
      </div>
      <span className=" text-gray-400">{subtitle}</span>
    </div>
  );
};
