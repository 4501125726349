import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const VIEWS = [
  { id: "overview", label: "Overview" },
  { id: "analytics", label: "Analytics" },
  { id: "participants", label: "Participants" },
  { id: "decks", label: "Decks" },
  { id: "description", label: "Description" },
];

export const Sidebar = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!hash || !VIEWS.map(({ id }) => "#" + id).includes(hash)) {
      navigate("#" + VIEWS[0].id);
    }
  }, [hash]);

  return (
    <nav>
      <ul className="flex flex-col gap-2">
        {VIEWS.map(({ id, label }) => {
          const active = hash && hash.substring(1) == id && "active";
          return (
            <li
              key={id}
              className={
                `font-semibold uppercase text-lg border border-black rounded-md hover:bg-black
              hover:text-white transition-all duration-250 cursor-pointer ` +
                (active ? "bg-black text-white" : "")
              }
            >
              <Link to={"#" + id}>
                <div className="p-2 px-3 ">{label}</div>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
