import { Button, FormikInput } from "components";
import { useFormik } from "formik";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useAuthStore } from "store";
import { apiCaller, extractError } from "utils";
import * as yup from "yup";

const schema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .required("The email is required"),
});

export const EmailForm = () => {
  const { user, updateEmail } = useAuthStore((state: any) => state);

  const formik = useFormik({
    initialValues: { email: "" },
    onSubmit: async (values) => {
      try {
        await apiCaller.post("/profile/email", values);
        updateEmail(values.email);
        toast.success("Email has been updated");
      } catch (err) {
        toast.error(extractError(err));
      }
    },
    validationSchema: schema,
  });

  useEffect(() => {
    formik.setFieldValue("email", user.email);
  }, []);

  return (
    <div className="border border-black rounded-md p-3">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <span className="uppercase font-bold">Update your Email Address</span>
        <FormikInput name="email" formik={formik} placeholder="Email address" />
        <div>
          <Button
            title="Update Email"
            type="submit"
            disabled={user.email == String(formik.values.email).toLowerCase()}
          />
        </div>
      </form>
    </div>
  );
};
