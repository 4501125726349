import { useRef, useState, FC, useEffect } from "react";

export const ImageInput: FC<any> = ({ formik, title, setImage: _setImage }) => {
  const inputRef: any = useRef();
  const [image, setImage] = useState<any>(null);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        _setImage(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      _setImage(null);
    }
  }, [image]);

  return (
    <div className="border border-black rounded-md flex gap-2 hover:bg-black hover:text-white transition-all duration-150">
      <div className="flex-1 ">
        <input
          ref={inputRef}
          type="file"
          className="hidden"
          onChange={(e: any) => {
            const image = e.target.files[0];
            formik.setFieldValue(`${title}.backgroundImage`, image);
            formik.setFieldValue(`${title}.deleteBackgroundImage`, false);
            setImage(image);
          }}
        />
        <button
          className="p-2 w-full text-start"
          type="button"
          onClick={() => inputRef.current.click()}
        >
          {image ? "Change" : "Upload"} Image
        </button>
        ​
      </div>
      <button
        type="button"
        className="text-black bg-white my-2 rounded-md border-black border  px-2"
        onClick={() => {
          setImage(null);
          formik.setFieldValue(`${title}.backgroundImage`, null);
          formik.setFieldValue(`${title}.deleteBackgroundImage`, true);
        }}
      >
        Remove
      </button>
      <button
        type="button"
        onClick={() => {
          setImage(null);
          formik.setFieldValue(`${title}.backgroundImage`, null);
          formik.setFieldValue(`${title}.deleteBackgroundImage`, false);
        }}
        className="text-black bg-white my-2 mr-2 rounded-md border-black border  px-2"
      >
        Reset
      </button>
    </div>
  );
};
