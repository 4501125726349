import { FC } from "react";
import { AlertTriangle } from "react-feather";

export const MessageView: FC<{ heading: string; message: string }> = ({
  heading,
  message,
}) => {
  return (
    <div className=" h-[100vh] flex justify-center items-center">
      <div className="bg-white p-7 px-7 rounded-md min-w-[400px] flex flex-col items-center">
        <AlertTriangle size="40px" />
        <p className="text-2xl font-bold pt-5">{heading}</p>
        <span>{message}</span>
      </div>
    </div>
  );
};
