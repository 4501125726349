import { apiCaller } from "utils";
import { create } from "zustand";
import { useSettingsStore } from "./settings";
import { ActionArgs } from "store";

export const useAuthStore = create((set) => ({
  user: null,
  loading: true,
  checkSession: async ({ onSuccess, onError, values }: ActionArgs) => {
    set((state: any) => ({ ...state, loading: true }));
    try {
      const {
        data: { data },
      } = await apiCaller.post("/auth/check");
      set((state: any) => ({
        ...state,
        user: data.user,
      }));
      useSettingsStore.setState((state: any) => ({ settings: data.settings }));
      onSuccess && onSuccess(values);
    } catch (err) {
      onError && onError(err);
    }
    set((state: any) => ({ ...state, loading: false }));
  },
  login: async ({ values, onSuccess, onError }: ActionArgs) => {
    set((state: any) => ({ ...state, loading: true }));
    try {
      const {
        data: { data },
      } = await apiCaller.post("/auth/login", values);
      useSettingsStore.setState((state: any) => ({ settings: data.settings }));
      set((state: any) => ({ ...state, user: data.user }));
      onSuccess && onSuccess();
    } catch (err) {
      onError && onError(err);
    }
    set((state: any) => ({ ...state, loading: false }));
  },
  logout: async ({ onSuccess, onError }: ActionArgs) => {
    set((state: any) => ({ ...state, loading: true }));
    try {
      await apiCaller.post("/auth/logout");
      onSuccess && onSuccess();
    } catch (err) {
      onError && onError(err);
    }
    set((state: any) => ({ ...state, loading: false }));
  },
  updateEmail: async (email: string) => {
    set((state: any) => ({ ...state, user: { ...state.user, email } }));
  },
}));
