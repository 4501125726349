import { REQUEST_WORKSHOP_ADMIN_ADMISSION } from "socketConstants";
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { toast } from "react-hot-toast";
import { apiCaller, extractError, socket } from "utils";

const WorkshopContext = createContext<any>({});
const WorkshopActionsContext = createContext<any>({});

export const useWorkshop = () => useContext(WorkshopContext);
export const useWorkshopActions = () => useContext(WorkshopActionsContext);

export const WorkshopProvider: React.FC<{
  children: ReactNode;
  id: string | undefined;
}> = ({ children, id }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [workshop, setWorkshop] = useState<any>(null);
  const [connectionEstablished, setConnectionEstablished] = useState(false);
  const [cardState, setCardState] = useState<any>({});
  const [actionLoading, setActionLoading] = useState(false);
  const [participantState, setParticipantState] = useState({});

  // load the workshop
  const load = async () => {
    setLoading(true);
    try {
      const {
        data: { data: workshop },
      } = await apiCaller.get("/workshops/" + id);
      setWorkshop(workshop);
      if (workshop.data && workshop.archived) {
        setParticipantState(workshop.data.participantState);
        setCardState(workshop.data.cardState);
      }
    } catch (err) {
      setError(true);
      toast.error(extractError(err));
    }
    setLoading(false);
  };

  // join the workshop room as the admin
  const joinRoom = async () => {
    socket.emit(REQUEST_WORKSHOP_ADMIN_ADMISSION, { workshopId: id });
  };

  // make the workshop public
  const makePublic = async ({ onSuccess }: { onSuccess: () => void }) => {
    setActionLoading(true);
    try {
      const {
        data: { data },
      } = await apiCaller.post(`/workshops/${id}/public`);
      setWorkshop(data);
      toast.success("The workshop have been made public");
      onSuccess();
    } catch (err) {
      toast.error(extractError(err));
    }
    setActionLoading(false);
  };

  // update the description of the workshop
  const updateDescription = async ({ data }: { data: string }) => {
    setActionLoading(true);
    try {
      const {
        data: { data: workshop },
      } = await apiCaller.post(`/workshops/${id}/description`, {
        data,
      });
      setWorkshop(workshop);
      toast.success("Description was successfully updated");
    } catch (err) {
      toast.error(extractError(err));
    }
    setActionLoading(false);
  };

  // lock the workshop
  const lock = async ({ onSuccess }: { onSuccess: () => void }) => {
    setActionLoading(true);
    try {
      const {
        data: { data },
      } = await apiCaller.post(`/workshops/${id}/lock`);
      setWorkshop(data);
      toast.success("The workshop have been locked");
      onSuccess();
    } catch (err) {
      toast.error(extractError(err));
    }
    setActionLoading(false);
  };

  // archive the workshop
  const archive = async ({ onSuccess }: { onSuccess: () => void }) => {
    setActionLoading(true);
    try {
      const {
        data: { data },
      } = await apiCaller.delete(`/workshops/${id}`);
      setWorkshop(data);
      if (data.data && data.archived) {
        setParticipantState(data.data.participantState);
        setCardState(data.data.cardState);
      }
      toast.success("The workshop have been archived");
      onSuccess();
    } catch (err) {
      toast.error(extractError(err));
    }
    setActionLoading(false);
  };

  // add a participant to the workshop
  const addParticipants = async ({
    emails,
    onSuccess,
  }: {
    emails: string;
    onSuccess: () => void;
  }) => {
    setActionLoading(true);
    try {
      const {
        data: { data },
      } = await apiCaller.post(`/workshops/${id}/participants`, {
        emails,
      });
      setWorkshop(data);
      toast.success("Participants have been successfully added");
      onSuccess();
    } catch (err) {
      toast.error(extractError(err));
    }
    setActionLoading(false);
  };

  // remove a participant from the workshop
  const removeParticipants = async ({
    email,
    onSuccess,
  }: {
    email: string;
    onSuccess: () => void;
  }) => {
    setActionLoading(true);
    try {
      const {
        data: { data },
      } = await apiCaller.delete(`/workshops/${id}/participants/${email}`);
      setWorkshop(data);
      toast.success("The participant have been removed");
      onSuccess();
    } catch (err) {
      toast.error(extractError(err));
    }
    setActionLoading(false);
  };

  // assign decks to the workshop
  const assignDecks = async (decks: any) => {
    setActionLoading(true);
    try {
      const {
        data: { data },
      } = await apiCaller.post(`/workshops/${id}/decks`, { decks });
      setWorkshop(data);
      toast.success("Decks have been assigned");
    } catch (err) {
      toast.error(extractError(err));
    }
    setActionLoading(false);
  };

  // submit a feedback against an email
  const submitFeedback = async ({
    values,
    onSuccess,
    onComplete,
  }: {
    values: any;
    onSuccess: () => void;
    onComplete: () => void;
  }) => {
    try {
      await apiCaller.post(`/workshops/${id}/feedback`, values);
      toast.success("The feedback has been submitted");
      onSuccess();
    } catch (err) {
      toast.error(extractError(err));
    }
    onComplete();
  };

  useEffect(() => {
    load();
  }, [id]);

  useEffect(() => {
    if (!workshop) return;
    if (workshop.locked) {
      joinRoom();
    }
  }, [workshop && workshop.locked]);

  useEffect(() => {
    socket.on("WORKSHOP_JOINED", (data) => {
      setConnectionEstablished(true);
    });
    socket.on("WORKSHOP_UPDATED", ({ cardState, participantState }) => {
      setCardState(cardState);
      setParticipantState(participantState);
    });
    return () => {
      socket.off("WORKSHOP_JOINED");
      socket.off("WORKSHOP_UPDATED");
    };
  }, [workshop && workshop.locked]);

  return (
    <WorkshopContext.Provider
      value={{
        connectionEstablished,
        cardState,
        actionLoading,
        workshop,
        loading,
        error,
        participantState,
      }}
    >
      <WorkshopActionsContext.Provider
        value={{
          load,
          addParticipants,
          removeParticipants,
          makePublic,
          archive,
          updateDescription,
          assignDecks,
          lock,
          submitFeedback,
        }}
      >
        {children}
      </WorkshopActionsContext.Provider>
    </WorkshopContext.Provider>
  );
};
