import { FC, useState } from "react";
import { Button, ConfirmationPopup } from "components";
import { Eye, Lock, Trash } from "react-feather";
import { useWorkshop, useWorkshopActions } from "../../workshopProvider";
import { useWorkshopStore } from "store";

const ActionBox: FC<any> = ({
  disabled,
  onClick,
  buttonText,
  buttonIcon,
  disabledText,
  disclaimer,
}) => {
  return (
    <div className="flex-1">
      <div className="flex gap-2 items-center">
        {!disabled && (
          <Button
            disabled={disabled}
            onClick={onClick}
            title={buttonText}
            icon={buttonIcon}
          />
        )}
        {disabled && (
          <span className="bg-black text-white p-2 rounded-md px-4 font-bold">
            {disabledText}
          </span>
        )}
      </div>
      <span className="block pt-2 max-w-[600px] text-gray-500">
        {disclaimer}
      </span>
    </div>
  );
};

export const OverviewActions = () => {
  const [showDelete, setShowDelete] = useState(false);
  const [showLock, setShowLock] = useState(false);
  const [showPublic, setShowPublic] = useState(false);

  const { workshop } = useWorkshop();
  const { makePublic, archive, lock } = useWorkshopActions();
  const { expire } = useWorkshopStore((state: any) => state);

  return (
    <>
      <ConfirmationPopup
        isOpen={showLock}
        onClose={() => setShowLock(false)}
        confirmationText="Lock"
        onConfirm={() => lock({ onSuccess: () => setShowLock(false) })}
        title="Lock Workshop"
      >
        <>Are you sure you want to lock the workshop?</>
      </ConfirmationPopup>
      <ConfirmationPopup
        isOpen={showDelete}
        onClose={() => setShowDelete(false)}
        confirmationText="Archive"
        onConfirm={() =>
          archive({
            onSuccess: () => {
              setShowDelete(false);
              expire();
            },
          })
        }
        title="Archive Workshop"
      >
        <>Are you sure you want to delete the workshop?</>
      </ConfirmationPopup>
      <ConfirmationPopup
        isOpen={showPublic}
        onClose={() => setShowPublic(false)}
        confirmationText="Make Public"
        onConfirm={() => makePublic({ onSuccess: () => setShowPublic(false) })}
        title="Make Workshop Public"
      >
        <>Are you sure you want to make the workshop public?</>
      </ConfirmationPopup>
      <div className="flex gap-5">
        <ActionBox
          disabled={workshop.locked}
          onClick={() => setShowLock(true)}
          buttonText="Lock Workshop"
          buttonIcon={<Lock size="16px" />}
          disabledText="WORKSHOP IS LOCKED"
          disclaimer="This action is irreversible. Once locked, you will be unable to change
          the decks and description. You must lock the workshop to start inviting
          participants."
        />
        <ActionBox
          disabled={workshop.public}
          onClick={() => setShowPublic(true)}
          buttonText="Make Public"
          buttonIcon={<Eye size="16px" />}
          disabledText="WORKSHOP IS PUBLIC"
          disclaimer="This action is irreversible. After making the workshop public, the
          participants will be able to view the cards and decks."
        />
        <ActionBox
          disabled={workshop.archived}
          onClick={() => setShowDelete(true)}
          buttonText="Archive Workshop"
          buttonIcon={<Trash size="16px" />}
          disabledText="WORKSHOP IS ARCHIVED"
          disclaimer=" This action is irreversible. Archiving the workshop will result in
          all the participants being disconnected and the workshop data being
          saved in the database for later viewing."
        />
      </div>
    </>
  );
};
