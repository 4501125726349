import { AdminLayout, PageHeader } from "components";
import { EmailForm } from "./EmailForm";
import { PasswordForm } from "./PasswordForm";

export const Profile = () => {
  return (
    <AdminLayout>
      <PageHeader title="Profile">
        <div className="flex flex-col w-[500px] gap-5">
          <EmailForm />
          <div className="border-b border-gray-300"></div>
          <PasswordForm />
        </div>
      </PageHeader>
    </AdminLayout>
  );
};
