import { FC } from "react";

export const Button: FC<{
  disabled?: boolean;
  title: string;
  onClick?: () => void;
  size?: "sm" | "md" | "lg";
  type?: "button" | "submit";
  icon?: any;
  className?: string;
}> = ({
  disabled,
  title,
  onClick,
  size = "lg",
  type = "button",
  icon,
  className = "",
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={() => onClick && onClick()}
      className={
        `border border-black p-1 px-2 rounded-md font-bold text-${size}
      hover:bg-black hover:text-white transition-all duration-700
      disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:text-black disabled:hover:bg-white ` +
        className
      }
    >
      <div className="flex gap-1 items-center">
        {icon}
        <span>{title}</span>
      </div>
    </button>
  );
};
