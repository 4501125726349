import { FC } from "react";

export const PageHeader: FC<{
  title: any;
  subTitle?: string;
  buttons?: any[];
  children?: React.ReactElement | React.ReactElement[];
}> = ({ title, subTitle, buttons, children }) => {
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex gap-2  justify-start items-end">
          {typeof title === "string" ? (
            <h1 className="text-3xl font-bold">{title}</h1>
          ) : (
            title
          )}

          <span className=" text-slate-500">{subTitle}</span>
        </div>
        <div className="flex gap-2">{buttons}</div>
      </div>
      <div className="pt-8 flex gap-10 w-[100%]">{children}</div>
    </div>
  );
};
