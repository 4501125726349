import { useMemo } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { Stack } from "./Stack";
import { useWorkshop, useWorkshopActions } from "../workshopProvider";
import { Collection } from "./Collection";

import collection1 from "assets/collectionImages/collection1.png";
import collection2 from "assets/collectionImages/collection2.png";
import collection3 from "assets/collectionImages/collection3.png";
import collection4 from "assets/collectionImages/collection4.png";

export const CardTable = () => {
  const { cardState, selectedDeck } = useWorkshop();

  const { updateWorkshopState, updateCardState } = useWorkshopActions();

  const deck = cardState[selectedDeck];

  const reorder = (list: any[], startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (
    source: any,
    destination: any,
    droppableSource: any,
    droppableDestination: any
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: any = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  function onDragEnd(result: any) {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    const newCards = { ...deck };
    if (sInd === dInd) {
      const items = reorder(deck[sInd], source.index, destination.index);
      newCards[sInd] = items;
    } else {
      const result = move(deck[sInd], deck[dInd], source, destination);
      newCards[sInd] = result[sInd];
      newCards[dInd] = result[dInd];
    }
    const updatedDeck = updateCardState(newCards);
    updateWorkshopState(updatedDeck);
  }

  const totalCardCount = useMemo(() => {
    let count = 0;
    try {
      Object.values(deck).forEach((d: any) => (count += d.length));
    } catch {}
    return count;
  }, [selectedDeck]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex flex-1 m-[20px] gap-[50px] overscroll-auto">
        <div className="flex-1">
          <Stack cards={deck.stack} totalCardCount={totalCardCount} />
        </div>
        <div className="flex-1">
          <Collection
            title="You are."
            icon={collection1}
            id="collection1"
            cards={deck.collection1}
          />
        </div>
        <div className="flex-1">
          <Collection
            title="You are not."
            icon={collection2}
            id="collection2"
            cards={deck.collection2}
          />
        </div>
        <div className="flex-1">
          <Collection
            title="You are torn."
            icon={collection3}
            id="collection3"
            cards={deck.collection3}
          />
        </div>
        <div className="flex-1">
          <Collection
            title="Does not apply."
            icon={collection4}
            id="collection4"
            cards={deck.collection4}
          />
        </div>
      </div>
    </DragDropContext>
  );
};
