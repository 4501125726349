import { FC } from "react";
import { WorkshopCard } from "./WorkshopCard";

export const WorkshopGrid: FC<{ workshops: WorkshopType[] }> = ({
  workshops,
}) => {
  return (
    <div className="grid grid-rows-2 grid-cols-4 gap-3 w-[100%]">
      {workshops.map((workshop) => (
        <div key={workshop._id}>
          <WorkshopCard workshop={workshop} />
        </div>
      ))}
    </div>
  );
};
