import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { AdminLayout, PageHeader, Button } from "components";
import { apiCaller, extractError } from "utils";
import { toast } from "react-hot-toast";
import { useSettingsStore } from "store";

export const AdminSettings = () => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: { cardRatio: "1x1" },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await apiCaller.post("/settings", values);
        useSettingsStore.setState((state: any) => ({ settings: values }));
        toast.success("Settings updated");
      } catch (err) {
        toast.error(extractError(err));
      }
      setLoading(false);
    },
  });

  const getSettings = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await apiCaller.get("/settings");
      formik.setValues(data);
    } catch (err) {
      toast.error(extractError(err));
    }
    setLoading(false);
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <AdminLayout loading={loading}>
      <PageHeader title="Settings">
        <div className="border rounded-lg w-[100vw] p-3 ">
          <form className="flex flex-col gap-5" onSubmit={formik.handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <td className="w-[200px]">Global Card Ratio</td>
                  <td>
                    <select
                      value={formik.values.cardRatio}
                      onChange={formik.handleChange}
                      name="cardRatio"
                      className="p-1 px-3 rounded-lg w-[100px]"
                    >
                      <option value="1x1">1 x 1</option>
                      <option value="1x2">1 x 2</option>
                      <option value="2x3">2 x 3</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <Button title="Save Settings" type="submit" />
            </div>
          </form>
        </div>
      </PageHeader>
    </AdminLayout>
  );
};
